define('clacksnack/components/application/-toolbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['app-toolbar'],

    searchPanel: Ember.inject.service(),

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    widgetPanel: Ember.inject.service(),

    loggedInUser: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    actions: {
      toggleWidgetPanel: function () {
        this.get('widgetPanel').toggleProperty('isOpen');
      },
      openSearchPanel: function () {
        this.searchPanel.set('isActive', true);
      }
    }
  });
});