define('clacksnack/components/asset-renderer/gallery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    scrollContainerService: Ember.inject.service('scroll-container'),

    classNames: ['asset__gallery'],
    classNameBindings: ['isFullscreen'],
    data: null,

    firstItem: Ember.computed('data', function () {
      return this.get('data')[0];
    }),

    secondItem: Ember.computed('data', function () {
      return this.get('data')[1];
    }),

    thirdItem: Ember.computed('data', function () {
      return this.get('data')[2];
    }),

    numberOfFurtherItems: Ember.computed('data', function () {
      return this.get('data.length') - 3;
    }),

    isFullscreen: false,

    hasMultipleItems: Ember.computed('data', function () {
      return this.get('data.length') > 1;
    }),

    galleryOptions: null,

    fullscreenChanged: Ember.observer('isFullscreen', function () {
      const self = this;
      if (this.get('isFullscreen')) {
        Ember.$(document).on('keydown.asset-gallery', function (e) {
          if (e.keyCode === 27) {
            self.send('toggleFullscreen');
          }
        });
      } else {
        Ember.$(document).off('keydown.asset-gallery');
      }
    }),

    onEnterFullscreen(initialSlide) {
      const options = {
        pagination: {
          type: "fraction"
        },
        navigation: {},
        zoom: {},
        keyboard: {
          enabled: true,
          onlyInViewport: false
        },
        initialSlide: initialSlide || 0
      };
      this.set('galleryOptions', options);
      this.get('scrollContainerService').set('isSmoothScrollingDisabled', true);
      this.set('isFullscreen', true);
      Ember.$(document).on('keydown.asset-gallery', function (e) {
        if (e.keyCode === 27) {
          self.send('leaveFullscreen');
        }
      });
    },

    onLeaveFullScreen() {
      this.set('isFullscreen', false);
      this.get('scrollContainerService').set('isSmoothScrollingDisabled', false);
      this.set('galleryOptions', true);
      Ember.$(document).off('keydown.asset-gallery');
    },

    actions: {
      enterFullscreen: function (initialSlide) {
        this.onEnterFullscreen(initialSlide);
      },
      leaveFullScreen: function () {
        this.onLeaveFullScreen();
      }
    }
  });
});