define('clacksnack/components/shared/toggle-switch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['toggle-switch'],

        name: null,

        isActive: false,

        isDisabled: false,

        onChange: function () {},

        change: function () {
            this.onChange();
        }
    });
});