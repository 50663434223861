define('clacksnack/components/project-radar/-time-slider', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        sliderLabel: null,
        months: null,
        max: 0,

        init() {
            this._super(...arguments);
            this.months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        },

        didInsertElement() {
            this._super(...arguments);

            let date = new Date();

            this.set('max', this.getDifferenceSinceRelease(date));

            this.updateLabel(date);
            this.updateSlider(date);
        },

        updateLabel(date) {
            this.set('sliderLabel', this.months[date.getMonth()] + " " + date.getFullYear());
        },

        getDifferenceSinceRelease(currentDate) {
            const difference = (0, _moment.default)(currentDate).diff((0, _moment.default)('2020-04-01'), 'months');

            return difference > 11 ? 11 : difference;
        },

        actions: {
            onInput() {
                let slider = this.element.querySelector('.time-slider');

                let value = slider.max - slider.value;
                let date = new Date();
                date.setMonth(date.getMonth() - value);

                this.updateLabel(date);
                // send requested date to project-radar, where data gets processed
                this.updateSlider(date);

                let percent = parseInt(slider.value) * 100 / parseInt(slider.max);

                let label = this.element.querySelector('.range-input-label');
                label.style.left = percent + "%";
                label.style.transform = "translateX(-" + percent + "%)";
            }
        }
    });
});