define('clacksnack/models/organisation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // Attributes

    abbreviation: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    brandColor: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),

    // Relationships
    employees: _emberData.default.hasMany('employee', { async: true }),
    locations: _emberData.default.hasMany('location', { async: true }),
    projects: _emberData.default.hasMany('project', { async: true })
  });
});