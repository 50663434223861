define('clacksnack/helpers/first-letter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.firstLetter = firstLetter;
  function firstLetter(params) {
    const text = params[0];
    return text[0];
  }

  exports.default = Ember.Helper.helper(firstLetter);
});