define('clacksnack/components/widget/feedback', ['exports', 'clacksnack/components/widget/_widget-content'], function (exports, _widgetContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetContent.default.extend({

    session: Ember.inject.service(),

    text: '',

    isDisabled: Ember.computed('text', function () {
      return this.get('text').length === 0;
    }),

    employee: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    actions: {
      submit: function () {

        if (this.get('isDisabled')) {
          return;
        }
        const text = this.get('text');
        const employee = this.get('employee');

        this.submit({
          text: text,
          abbreviation: employee.get('abbreviation')
        });

        this.set('text', '');
      }
    }
  });
});