define('clacksnack/services/global-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    init: function () {
      this._super();
      this.set('config', {
        locations: ['Wismar', 'Berlin', 'Hamburg'],
        organisations: ['click solutions', 'Lachs von Achtern'],
        apps: [{
          label: 'absence.io',
          url: 'https://app.absence.io/#/actions/login',
          logo: 'absence.png'
        }, {
          label: 'TW Projects',
          url: 'https://clacks.teamwork.com',
          logo: 'teamwork-projects.png'
        }, {
          label: 'TW Chat',
          url: 'https://clacks.teamwork.com/chat',
          logo: 'teamwork-chat.png'
        }, {
          label: 'Keeper',
          url: 'https://keepersecurity.eu/vault/#',
          logo: 'keeper.png'
        }, {
          label: 'HQlabs',
          url: 'https://clacks.hqlabs.de',
          logo: 'hqlabs.png'
        }, {
          label: 'overclacks',
          url: 'https://over.clacks.de/',
          logo: 'overclacks.png'
        }, {
          label: 'clackswiki',
          url: 'https://wiki.clacks.de/',
          logo: 'clackswiki.png'
        }, {
          label: 'Filebeamer',
          url: 'https://share.click-solutions.de/',
          logo: 'filebeamer.png'
        }, {
          label: 'Gitlab',
          url: 'https://gitlab.clacks.de',
          logo: 'gitlab.png'
        }]
      });
    }
  });
});