define('clacksnack/components/asset-renderer/link', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    imageSrc: null,
    data: null,
    didEnterViewport() {
      this.set("imageSrc", this.get("data.poster"));
    }
  });
});