define('clacksnack/components/project-radar/-spot', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        project: null, // component parameter

        //styles
        classNames: ['spot'],
        // attributes
        modalIsVisible: false,

        // Lifecycle Hook
        didRender() {
            this.setPosition();
        },

        projectChanged: Ember.observer('project', 'project.positionLeft', 'project.positionBottom', function () {
            this.setPosition();
        }),

        setPosition() {
            this.element.style.left = this.get('project').positionLeft + "%";
            this.element.style.bottom = this.get('project').positionBottom + "%";
            this.element.querySelector('.outerRing').style.backgroundColor = this.get('project').color;
            this.element.querySelector('.innerRing').style.backgroundColor = this.get('project').color;
        },

        // Actions
        actions: {
            showModal() {
                this.set("modalIsVisible", true);
            }
        }
    });
});