define('clacksnack/components/widget-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    settings: Ember.inject.service('app-settings'),

    classNames: ['widget-panel'],
    classNameBindings: ['isOpen'],

    state: Ember.inject.service('widget-panel'),

    isEditing: false,

    isOpen: Ember.computed('state.isOpen', function () {
      return this.get('state.isOpen');
    }),

    stateChange: Ember.observer('state.isOpen', () => {
      Ember.$('html, body').toggleClass('overflow-hidden');
    }),

    data: null,
    widgets: Ember.computed('data', function () {
      let config = this.get('settings.config');
      return this.get('data').map(widget => {
        widget.set('template', `widget/${widget.name}`);
        return widget;
      }).filter(widget => {
        // always show mandatory widgets
        let isMandatory = widget.mandatory === true;
        let shouldShow = config[widget.name] === true;
        return this.componentExists(widget.template) && (isMandatory || shouldShow);
      });
    }),

    init: function () {
      this._super(...arguments);
      this.set('data', []);
    },
    didInsertElement: function () {
      this._super(...arguments);
      this.get('store').findAll('widget').then(result => {
        // debugger;
        this.set('data', result);
      });
    },

    componentExists: function (componentName) {
      let owner = Ember.getOwner(this);
      return owner.hasRegistration(`component:${componentName}`);
    },

    actions: {

      edit: function () {
        this.set('isEditing', true);
      },
      close: function () {
        this.get('state').set('isOpen', false);
      }
    }
  });
});