define('clacksnack/services/tracking', ['exports', 'clacksnack/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        propertyID: _environment.default.APP.GOOGLE_ANALYTICS_ID,

        trackView(data) {
            if (data.title && data.path) {
                // eslint-disable-next-line no-undef
                gtag('config', this.get('propertyID'), {
                    'page_title': data.title,
                    'page_path': data.path
                });
            }
        },

        trackEvent(category, action, label, value) {
            if (!category && !action) {
                return;
            }

            var payload = {
                'event_category': category
            };

            if (label) {
                Object.assign(payload, {
                    'event_label': label
                });
            }

            if (value) {
                Object.assign(payload, {
                    'value': value
                });
            }

            // eslint-disable-next-line no-undef
            gtag('event', action, payload);
        }
    });
});