define('clacksnack/mixins/tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    tracking: Ember.inject.service(),
    afterModel: function () {
      this._super(...arguments);

      // eslint-disable-next-line no-unused-vars
      const [model, tracking] = arguments;

      tracking.then(data => {
        // eslint-disable-next-line no-unused-vars
        const [hash, ...url] = window.location.hash;

        this.get('tracking').trackView({
          title: data.routeName,
          path: url.join("")
        });
      });
    }
  });
});