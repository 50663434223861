define('clacksnack/components/user-profile/-cover-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-profile__cover-image'],
    src: null,
    alt: null
  });
});