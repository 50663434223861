define('clacksnack/components/post-panel/-assets', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['assets'],
        assets: null,

        gallery: Ember.computed('assets', function () {
            return this.get('assets').filterBy('mediaType', 'image');
        }),

        link: Ember.computed('assets', function () {
            return this.get('assets').filterBy('mediaType', 'link')[0];
        }),

        embeddedVideo: Ember.computed('assets', function () {
            return this.get('assets').filterBy('mediaType', 'embedded-video')[0];
        })
    });
});