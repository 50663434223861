define('clacksnack/components/profile-picture', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['avatar'],
    src: null,
    alt: null,
    supplementColor: null
  });
});