define("clacksnack/components/post-stream", ["exports", "clacksnack/config/environment", "moment"], function (exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // services
    store: Ember.inject.service(),
    stream: Ember.inject.service("post-stream"),
    settings: Ember.inject.service("app-settings"),
    tracking: Ember.inject.service("tracking"),

    //
    classNames: ["post-stream"],
    classNameBindings: ["isLoading"],

    // data
    posts: null,

    // states
    isLoading: Ember.computed('stream.isLoading', function () {
      return this.get('stream').get('isLoading');
    }),

    // utilities
    interval: null,
    scrollPositionY: 0,

    //
    didInsertElement: function () {
      this._super(...arguments);

      const stream = this.get('stream');
      stream.set("posts", []);
      stream.set("isLoading", true);
      stream.load({}, results => {
        stream.set("isLoading", false);
        stream.get('posts').pushObjects(results.toArray());
        //
        this.$().closest('.scroll-container').on("scroll", () => {
          this.didScroll();
        });
        //
        this.setInterval();
      });
    },

    //
    willDestroyElement: function () {
      this._super(...arguments);
      this.$().closest('.scroll-container').scrollTop(0);
      this.$().closest('.scroll-container').off("scroll");
      this.clearInterval();
      this.get("stream").set('hasUpdates', false);
      this.get("stream").set('posts', null);
    },

    didScroll: function () {
      if (!this.get("isLoading")) {
        if (this.isScrolledToBottom()) {
          this.loadOlderEntries();
        }
      }
      this.set("scrollPositionY", this.$().closest('.scroll-container').scrollTop());
    },

    //
    isScrolledToBottom: function () {
      const scrollPositionY = this.get("scrollPositionY");
      const distanceToTop = this.$().height() - this.$().closest('.scroll-container').height();
      const top = this.$().closest('.scroll-container').scrollTop();
      return top >= distanceToTop && scrollPositionY < top;
    },

    //
    setInterval: function () {
      this.clearInterval();
      const interval = setInterval(() => {
        this.checkUpdates();
      }, _environment.default.APP.INTERVAL);
      this.set("interval", interval);
    },

    //
    clearInterval: function () {
      clearInterval(this.get("interval"));
    },

    //

    //
    checkUpdates: function () {
      const item = this.get("stream").get("firstItem");
      if (item) {
        const postedOn = item.get("postedOn");
        this.get("stream").load({
          after: (0, _moment.default)(postedOn).format()
        }, posts => {
          if (posts.length) {
            this.get('stream').set('hasUpdates', true);
          }
        });
      }
    },

    //
    loadOlderEntries: function () {
      if (!this.get("isLoading")) {
        const item = this.get("stream").get("lastItem");
        if (item) {
          this.get('stream').set("isLoading", true);
          const postedOn = item.get("postedOn");
          this.get("stream").load({
            before: (0, _moment.default)(postedOn).format()
          }, results => {
            this.get('stream').append(results);
            this.get('stream').set("isLoading", false);
            this.get('tracking').trackEvent('post_stream', 'load_more', this.get('stream.posts.length'));
          });
        }
      }
    }
  });
});