define('clacksnack/initializers/fastclick', ['exports', 'ember-cli-fastclick/initializers/fastclick'], function (exports, _fastclick) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fastclick.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _fastclick.initialize;
    }
  });
});