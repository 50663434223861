define('clacksnack/services/session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    router: Ember.inject.service(),
    store: Ember.inject.service(),

    session: null,

    init: function () {
      this._super(...arguments);
      this.createSession();
    },

    createSession() {
      const session = window.localStorage.session || '{}';
      this.set('session', JSON.parse(session));
    },

    isLoggedIn() {
      const session = this.get('session');
      return !Ember.isEmpty(session) && !Ember.isEmpty(session.token);
    },

    login(session) {
      window.localStorage.setItem('session', JSON.stringify({
        token: session.get('token'),
        employeeId: session.get('employee').get('id')
      }));
      this.createSession();
    },

    logout() {
      window.localStorage.removeItem('session');
      this.set('session', null);
      this.get('router').transitionTo('login');
    }
  });
});