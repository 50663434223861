define('clacksnack/components/post-panel/-edit', ['exports', 'clacksnack/utils/strip-html'], function (exports, _stripHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    notifications: Ember.inject.service('notification-messages'),

    classNames: ['post__edit-form'],
    post: null,

    isEditing: false,
    isSending: false,
    isEmojiPanelOpen: false,

    fullTextEdit: Ember.computed('post.fulltext', function () {
      return this.get('post.fulltext');
    }),

    isEmptyOrUnchangedEditOrIsSending: Ember.computed('fullTextEdit', 'isSending', function () {
      return this.get('fullTextEdit').length === 0 || this.get('post.fulltext').trim() === this.get('fullTextEdit').trim() || this.get('isSending') === true;
    }),

    submitPostEdit: function () {

      const fulltext = (0, _stripHtml.stripHTML)(this.get('fullTextEdit'));

      this.set('isSending', true);
      const post = this.get('post');
      post.set('fulltext', fulltext);
      post.save().then(() => {
        this.set('isEditing', false);
        this.set('isSending', false);
        this.set('isEmojiPanelOpen', false);
      }).catch(() => {
        this.onError();
        this.set('isSending', false);
      });
    },

    onError() {
      const errorMessage = this.get('intl').t('appStatusCode.100');
      this.get('notifications').error(errorMessage, {
        autoClear: true,
        clearDuration: 5000,
        cssClasses: 'notification--error'
      });
    },

    actions: {

      cancelEditing: function () {
        this.set("isEditing", false);
      },

      submitEditedPost: function () {
        this.set('isEmojiPanelOpen', false);
        this.submitPostEdit();
      },

      toggleEmojiPanel: function () {
        this.set('isEmojiPanelOpen', !this.get('isEmojiPanelOpen'));
      }
    }
  });
});