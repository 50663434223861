define('clacksnack/components/project-radar/-tooltip', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['tooltip'],

        //data
        project: null,

        // Lifecycle Hook
        didInsertElement() {
            this._super(...arguments);
            if (this.project.tooltipIsInverse) {
                this.element.classList.add('tooltip-inverse');
            }
        }
    });
});