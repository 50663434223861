define('clacksnack/routes/teams', ['exports', 'clacksnack/mixins/tracking'], function (exports, _tracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tracking.default, {
    model() {
      return this.store.findAll('team');
    }
  });
});