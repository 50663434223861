define('clacksnack/components/application/-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'header',
        classNames: ['app-header'],
        classNameBindings: ['isUp'],

        delta: 5,
        lastScrollTop: 0,
        didScroll: false,
        navbarHeight: null,
        scrollContainer: null,
        isUp: false,

        didInsertElement() {
            this._super(...arguments);
            this.onInit();
        },

        onScroll() {
            const scrollTop = this.get('scrollContainer').scrollTop();
            const delta = this.get('delta');
            const lastScrollTop = this.get('lastScrollTop');
            const navbarHeight = this.get('navbarHeight');

            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - scrollTop) <= delta) return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
                // Scroll Down
                this.set('isUp', true);
            } else {
                this.set('isUp', false);
                // Scroll Up
                // if(scrollTop + $(window).height() < $(document).height()) {
                //     // $('header').removeClass('nav-up').addClass('nav-down');
                // }
            }

            this.set('lastScrollTop', scrollTop);
        },

        onInit() {

            this.set('scrollContainer', Ember.$('.main'));
            this.get('scrollContainer').on('scroll', () => {
                this.set('didScroll', true);
            });

            setInterval(() => {
                if (this.get('didScroll')) {
                    this.onScroll();
                    this.set('didScroll', false);
                }
            }, 250);
        }
    });
});