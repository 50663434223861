define('clacksnack/routes/search', ['exports', 'clacksnack/mixins/tracking'], function (exports, _tracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_tracking.default, {
        queryParams: {
            q: {
                refreshModel: true
            }
        },
        model(params) {
            return this.store.query('search-item', { query: params.q });
        },
        actions: {
            enterSearchField: function () {
                Ember.$('.search__input').trigger('click');
            }
        }
    });
});