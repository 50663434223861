define('clacksnack/mirage/factories/comment', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  exports.default = _emberCliMirage.default.Factory.extend({
    fulltext: _faker.default.lorem.text,
    postedOn: _faker.default.date.past,
    afterCreate(comment, server) {
      const employee = server.schema.employees.find(getRandomNumber(1, 20));
      if (!comment.employee) {
        comment.update({
          employee
        });
      }
      if (comment.events.length === 0) {
        comment.events = server.createList('event', getRandomNumber(1, 5), {
          source: comment
        });
      }
    }
  });
});