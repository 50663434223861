define('clacksnack/mirage/scenarios/default', ['exports', 'faker'], function (exports, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {

    // Server Config

    server.timing = 1000;

    // Fixtures

    server.loadFixtures('competences');
    server.loadFixtures('roles');
    server.loadFixtures('organisations');
    server.loadFixtures('locations');

    // Factory Data

    // Empployees

    server.createList('employee', 10, 'ClickSolutions', 'Wismar', {
      isActive: true
    });
    server.createList('employee', 10, 'LachsVonAchtern', 'Wismar');
    server.createList('employee', 5, 'ClickSolutions', 'Berlin');
    server.createList('employee', 5, 'LachsVonAchtern', 'Berlin');
    server.createList('employee', 5, 'ClickSolutions', 'Hamburg');

    const loggedInUser = server.schema.employees.find(1);
    const manager = server.schema.employees.find(2);
    const supervisor = server.schema.employees.find(3);

    loggedInUser.supervisedBy = supervisor;
    loggedInUser.managedBy = manager;
    loggedInUser.supervises = [server.schema.employees.find(4), server.schema.employees.find(5)];
    loggedInUser.manages = [server.schema.employees.find(6), server.schema.employees.find(7)];

    // Posts

    server.create('post');
    server.create('post', 'withLink');
    server.create('post', 'withImage');
    server.create('post', 'withImageGallery');
    server.create('post', 'withEmbeddedVideo');
    server.createList('post', 10);

    setTimeout(function () {
      server.create('post', 'isFresh');
    }, 10000);

    // Teams

    server.createList('team', 10);

    // Projects

    server.createList('project-category', 5);

    // Widget

    server.create('widget', {
      name: "employee-presence",
      title: "Isser da?",
      description: null,
      mandatory: true,
      details: {
        'presences': [{
          employee: {
            id: 3,
            name: "Steffen Glowczak",
            avatar: _faker.default.internet.avatar()
          },
          state: {
            name: "later",
            title: "Später"
          },
          from: "2018-05-14T09:29:00+02:00",
          to: null,
          comment: "Erlangen"
        }, {
          employee: {
            id: 6,
            name: "Thomas Langemann",
            avatar: _faker.default.internet.avatar()
          },
          state: {
            name: "home-office",
            title: "Home-Office"
          },
          from: "2018-05-14T09:29:00+02:00",
          to: null,
          comment: "Vormittags, Nachmittags im Büro"
        }, {
          employee: {
            id: 14,
            name: "Andi Völpel",
            avatar: _faker.default.internet.avatar()
          },
          state: {
            name: "vacation",
            title: "Urlaub"
          },
          from: "2018-05-14T09:29:00+02:00",
          to: "2018-05-28T00:00:00+02:00",
          comment: null
        }, {
          employee: "Paul Facklam",
          state: {
            name: "ill",
            title: "Krank"
          },
          from: "2018-05-15T09:29:00+02:00",
          to: "2018-05-18T00:00:00+02:00",
          comment: "Mit Kind krank. Vorraussichtlich am nächsten Tag wieder im Büro"
        }]
      }
    });

    server.create('widget', {
      name: "employee-birthday",
      title: "Happy Birthday",
      description: null,
      mandatory: true,
      details: {
        'birthdays': [{
          id: 14,
          name: "Andi Völpel",
          avatar: _faker.default.internet.avatar(),
          age: 28,
          date: "2018-11-02",
          isBirthdayToday: true,
          teamworkId: 74179
        }, {
          id: 1,
          name: "Paul Facklam",
          avatar: _faker.default.internet.avatar(),
          age: 35,
          date: "2018-10-1983",
          isBirthdayToday: false,
          teamworkId: 74171
        }]
      }
    });

    server.create('widget', {
      name: "meeting-room",
      title: "Meeting Room",
      description: "",
      mandatory: true,
      details: {
        rooms: [{
          "city": "HWI",
          "name": "HWI-OG-Landseite (6)",
          "isAvailable": false,
          "avatar": _faker.default.internet.avatar(),
          "meetings": [{
            "from": "2018-06-27T10:00:00+02:00",
            "to": "2018-06-27T11:00:00+02:00",
            "title": "cabledrum.com - Treffen Maika",
            "link": "https://www.google.com/calendar/event?eid=NWFsbG5iYjB2bG9ncHB1ZDhsajl0NDVocTIgY2xhY2tzLmRlXzM3MzczNDMyMzEzOTMxMzAzMTM3MzZAcmVzb3VyY2UuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&ctz=Europe/Berlin",
            "isPast": true
          }, {
            "from": "2018-06-27T11:00:00+02:00",
            "to": "2018-06-27T11:50:00+02:00",
            "title": "SIAG - SV - LifeNet - Planung",
            "link": "https://www.google.com/calendar/event?eid=MGdlaW04b3Mwa2lsdmZtazBxZjZwcTc5cnQgY2xhY2tzLmRlXzM3MzczNDMyMzEzOTMxMzAzMTM3MzZAcmVzb3VyY2UuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&ctz=Europe/Berlin",
            "isPast": false
          }, {
            "from": "2018-06-27T13:00:00+02:00",
            "to": "2018-06-27T14:00:00+02:00",
            "title": "SIAG: Magnetom Sola Cardio - Informer Animation",
            "link": "https://www.google.com/calendar/event?eid=MTRncmZjaWduZW1waXJ0ZjkxaTY0MHVucGIgY2xhY2tzLmRlXzM3MzczNDMyMzEzOTMxMzAzMTM3MzZAcmVzb3VyY2UuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&ctz=Europe/Berlin",
            "isPast": false
          }, {
            "from": "2018-06-27T14:00:00+02:00",
            "to": "2018-06-27T14:50:00+02:00",
            "title": "CLAC - IT Android auf Dev-Macbook",
            "link": "https://www.google.com/calendar/event?eid=Mzl1N3MxaDlhMTg4dTRldWphcmZkanJkNGIgY2xhY2tzLmRlXzM3MzczNDMyMzEzOTMxMzAzMTM3MzZAcmVzb3VyY2UuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&ctz=Europe/Berlin",
            "isPast": false
          }, {
            "from": "2018-06-27T15:00:00+02:00",
            "to": "2018-06-27T15:50:00+02:00",
            "title": "SIAG - MSC - DocLib - Weekly Status Update",
            "link": "https://www.google.com/calendar/event?eid=ZzkycjBlbjJ2OTI5ZWtuZTA4b2xrZmt0MTRfMjAxODA2MjdUMTMwMDAwWiBjbGFja3MuZGVfMzczNzM0MzIzMTM5MzEzMDMxMzczNkByZXNvdXJjZS5jYWxlbmRhci5nb29nbGUuY29t&ctz=Europe/Berlin",
            "isPast": false
          }]
        }, {
          "city": "HWI",
          "name": "HWI-OG-Thinktank (8)",
          "isAvailable": false,
          "meetings": []
        }, {
          "city": "HWI",
          "name": "HWI-OG-Seeseite (8)",
          "isAvailable": true,
          "meetings": [{
            "from": "2018-06-27T08:45:00+02:00",
            "to": "2018-06-27T11:00:00+02:00",
            "title": "SIAG SV LifeNet Redesign - Was ist LifeNet und was habe ich damit zu tun?",
            "link": "https://www.google.com/calendar/event?eid=Nmd0Mmg0ZHNscHVpdGlrN3I0aGd2bDc2aGQgY2xhY2tzLmRlXzJkMzkzMDMwMzUzOTMxMzkzMjMxMzAzOEByZXNvdXJjZS5jYWxlbmRhci5nb29nbGUuY29t&ctz=Europe/Berlin",
            "isPast": true
          }, {
            "from": "2018-06-27T10:00:00+02:00",
            "to": "2018-06-27T11:00:00+02:00",
            "title": "SIAG - Doclib Tutorial",
            "link": "https://www.google.com/calendar/event?eid=NWRuOHRhYTI1dDl1MTFjbGg5bm5jNWZoZjggY2xhY2tzLmRlXzJkMzkzMDMwMzUzOTMxMzkzMjMxMzAzOEByZXNvdXJjZS5jYWxlbmRhci5nb29nbGUuY29t&ctz=Europe/Berlin",
            "isPast": true
          }, {
            "from": "2018-06-27T14:00:00+02:00",
            "to": "2018-06-27T15:00:00+02:00",
            "title": "SIAG: Magnetom Sola Cardio - Teaser Animation",
            "link": "https://www.google.com/calendar/event?eid=MGJncHUxMWVrbWNobHY2MzhjMGRocXJlOTAgY2xhY2tzLmRlXzJkMzkzMDMwMzUzOTMxMzkzMjMxMzAzOEByZXNvdXJjZS5jYWxlbmRhci5nb29nbGUuY29t&ctz=Europe/Berlin",
            "isPast": false
          }, {
            "from": "2018-06-27T16:00:00+02:00",
            "to": "2018-06-27T18:00:00+02:00",
            "title": "WM2018: Deutschland gegen Südkorea",
            "link": "https://www.google.com/calendar/event?eid=ajJnN2VpanZvZHBya3ZlbTQ4ZW0xYTV2a28gY2xhY2tzLmRlXzJkMzkzMDMwMzUzOTMxMzkzMjMxMzAzOEByZXNvdXJjZS5jYWxlbmRhci5nb29nbGUuY29t&ctz=Europe/Berlin",
            "isPast": false
          }]
        }]
      }
    });

    server.create('widget', {
      name: "feedback",
      title: "Feedback",
      description: 'Dein Feedback ist gefragt! Was gefällt dir? Was gefällt dir nicht? Sag\'s uns!',
      mandatory: true,
      details: null
    });

    server.create('widget', {
      name: "u-already-know",
      title: "Kennst du schon...?",
      description: null,
      mandatory: true,
      details: {
        id: 14,
        name: "Andi Völpel",
        abbreviation: 'AV',
        organisation: 'click solutions',
        avatar: _faker.default.internet.avatar(),
        text: "Adipisicing est consectetur aliqua fugiat incididunt ad ullamco non adipisicing cillum aliquip ex consectetur. Cupidatat aute cillum ut velit.\n\nConsequat pariatur deserunt consectetur non reprehenderit do id velit ullamco sit ea aute. Sint proident laboris aute esse qui labore ut. Ut eiusmod nostrud sunt culpa. Veniam nisi consequat voluptate fugiat."
      }
    });

    server.create('widget', {
      name: "unknown-component",
      title: "Unknow",
      description: '',
      mandatory: true,
      details: null
    });

    server.db.dump();
  };
});