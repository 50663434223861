define('clacksnack/components/user-profile/-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['card', 'user-profile__card'],

        title: null,
        isEditing: false,
        isEditable: true,

        onSave() {},
        onCancel() {},
        onClose() {
            this.set('isEditing', false);
        },

        actions: {
            edit: function () {
                this.set('isEditing', !this.get('isEditing'));
            },

            cancel: function () {
                this.onCancel();
                this.onClose();
            },
            save: function () {
                this.onSave();
                this.onClose();
            }
        }
    });
});