define('clacksnack/components/back-to-top', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: "button",
    classNames: ["button", "button--control", "back-to-top"],
    classNameBindings: ["isVisible"],

    isVisible: false,

    didInsertElement() {
      Ember.$('.scroll-container').on('scroll', e => {
        Ember.run(() => {
          this.set('isVisible', e.currentTarget.scrollTop > 0);
        });
      });
    },

    willDestroyElement() {
      Ember.$('.scroll-container').off('scroll');
    },

    scrollUp() {
      Ember.$('.scroll-container').stop().animate({
        scrollTop: 0
      }, 500, 'swing');
    },

    click() {
      this.scrollUp();
    }
  });
});