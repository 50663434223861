define('clacksnack/components/post-form', ['exports', 'clacksnack/utils/url-regex', 'clacksnack/utils/strip-html'], function (exports, _urlRegex, _stripHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stream: Ember.inject.service('post-stream'),
    notifications: Ember.inject.service('notification-messages'),
    scrollContainerService: Ember.inject.service('scroll-container'),

    tagName: 'form',
    classNames: ['card', 'post-form'],
    classNameBindings: ['isEnabled', 'isSending'],

    // Async data
    employee: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    fulltext: '',
    assets: Ember.A(),
    // assetIdx: 0,

    // Computed Properties

    assetType: Ember.computed('assets.[]', function () {

      var assets = this.get('assets');

      if (assets.length === 0) {
        return null;
      }

      return assets[0].get('mediaType');
    }),
    assetComponent: Ember.computed('assetType', function () {
      return `asset-editor/${this.get('assetType')}`;
    }),

    // States

    isSending: false,
    isEnabled: false,
    isQuerying: false,

    isDisabled: Ember.computed('isEmpty', 'isSending', function () {
      return this.get('isEmpty') || this.get('isSending');
    }),

    isEmpty: Ember.computed('fulltext', 'hasAssets', function () {
      return !this.get('hasFulltext') && !this.get('hasAssets');
    }),

    hasFulltext: Ember.computed('fulltext', function () {
      return this.get('fulltext').length > 0;
    }),

    hasAssets: Ember.computed('assets.[]', function () {
      return this.get('assets').length > 0;
    }),

    isSingleAsset: Ember.computed('assetType', function () {
      return this.get('assetType') !== null && this.get('assetType') !== 'image';
    }),

    // States for used components

    isLinkModalOpen: false,
    isCancelModalOpen: false,
    isEmojiPanelOpen: false,

    // Methods

    extractLink: function (text) {
      var pattern = (0, _urlRegex.urlRegex)();
      var regex = new RegExp(pattern);
      var result = regex.exec(text);

      return result;
    },

    queryLink: function (url) {

      url = encodeURI(url);

      this.set('isQuerying', true);

      this.get('store').queryRecord('opengraph', { url }).then(link => {
        if (link.get('media') !== null) {
          const asset = this.get('store').createRecord('asset', {
            mediaType: link.get('mediaType'),
            title: link.get('title'),
            description: link.get('description'),
            poster: link.get('poster'),
            media: link.get('media')
          });

          this.set('assets', [asset]);
        } else {
          this.get('notifications').info(this.get('intl').t('post.form.link-not-found'), {
            autoClear: true,
            clearDuration: 5000,
            cssClasses: 'notification--info'
          });
        }

        this.set('isQuerying', false);
      });
    },

    init: function () {
      this._super(...arguments);
      this.onInit();
    },

    reset: function () {
      this.set('isEnabled', false);
      this.set('fulltext', '');
      this.set('upload.index', 0);
      this.set('upload.status', 0);
      this.set('isEmojiPanelOpen', false);
      this.onRemoveAssets();
    },

    //
    // Event Hooks
    //

    onInit: function () {
      // this.set('assets', []);
      this.set('upload', {
        status: 0,
        index: 0
      });
    },

    //
    onInput: function () {

      const fulltext = this.get('fulltext');
      const result = this.extractLink(fulltext);

      if (result) {
        var url = result[0];
        this.queryLink(url);
      }
    },

    //
    onFileSelect: function (e) {

      this.onEnable();

      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const reader = new FileReader();
        const image = this.get('store').createRecord('asset', {
          mediaType: 'image'
        });
        this.get('assets').pushObject(image);
        reader.onload = e => {
          image.set('media', e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },

    //
    onEnable: function () {
      this.get('scrollContainerService').set('isSmoothScrollingDisabled', true);
      this.set('isEnabled', true);
      setTimeout(() => {
        this.$('textarea').focus();
      }, 100);
    },

    //
    onCancel: function () {
      this.reset();
      this.get('scrollContainerService').set('isSmoothScrollingDisabled', false);
    },

    //
    onLinkSubmit: function () {

      this.set('isLinkModalOpen', false);
      const link = this.get('link');
      const result = this.extractLink(link);

      if (result) {
        var url = result[0];
        this.queryLink(url);
      }

      this.set('link', '');
    },

    onRemoveAssets: function () {
      this.set('assets', []);
    },

    onSubmit: function () {
      this.set('isSending', true);
      this.submitPost();
    },

    onError() {
      this.set('isSending', false);
      this.set('upload', {
        status: 0,
        index: 0
      });
      const errorMessage = this.get('intl').t('appStatusCode.100');
      this.get('notifications').error(errorMessage, {
        autoClear: true,
        clearDuration: 5000,
        cssClasses: 'notification--error'
      });
    },

    submitPost: function () {

      const fulltext = (0, _stripHtml.stripHTML)(this.get('fulltext'));

      const post = this.get('store').createRecord('post', {
        fulltext: fulltext,
        employee: this.get('employee')
      });

      post.save().then(() => {
        // send assets if given
        if (this.get('assets').length > 0) {
          this.submitAssets(post);
        } else {
          this.publishPost(post);
        }
      }).catch(() => {
        this.onError();
      });
    },

    submitAssets: function (post) {

      this.set('upload.status', 1);
      const assets = this.get('assets');
      const index = this.get('upload.index');

      if (index < assets.length) {
        const asset = assets.objectAt(index);
        asset.set('post', post);
        asset.save().then(() => {

          this.set('upload.index', index + 1);
          this.submitAssets(post);
        }).catch(() => {
          this.onError();
        });
      } else {
        this.publishPost(post);
      }
    },

    publishPost: function (post) {
      this.set('upload.status', 2);
      post.set('isPublished', true);
      post.save().then(() => {
        this.set('upload.status', 3);
        setTimeout(() => {
          this.set('isSending', false);
          this.reset();
          this.get('scrollContainerService').set('isSmoothScrollingDisabled', false);
          this.get('stream').prepend([post]);
        }, 2000);
      });
    },

    //
    // Events
    //

    keyUp: function (evt) {
      var keyCode = evt.keyCode;
      if (keyCode === 32) {
        if (!this.get('assetType')) {
          this.onInput();
        }
      }
    },

    paste: function () {
      if (!this.get('assetType')) {
        setTimeout(() => {
          this.onInput();
        }, 50);
      }
    },

    submit: function (e) {

      e.preventDefault();

      if (!this.get('isSending')) {
        if (this.get('isDisabled')) {
          this.onEnable();
        } else {
          this.onSubmit();
        }
      }
    },

    //
    // Actions
    //

    actions: {

      enable: function () {
        this.onEnable();
      },

      cancel: function () {
        this.onCancel();
      },

      addImage: function () {
        this.$('input[type="file"]').trigger('click');
      },

      addLink: function () {
        this.set('isLinkModalOpen', true);
        setTimeout(() => {
          const modal = this.$().find('.modal.is-open');
          const input = modal.find('input[type="text"]');
          input.focus();
        }, 100);
      },

      cancelLinkModal: function () {
        this.set('isLinkModalOpen', false);
        this.set('link', null);
      },

      submitLinkModal: function () {
        this.onLinkSubmit();
      },

      openCancelModal: function () {
        if (this.get('isDisabled')) {
          this.onCancel();
        } else {
          this.set('isCancelModalOpen', true);
        }
      },

      closeCancelModal: function () {
        this.set('isCancelModalOpen', false);
      },

      removeAsset: function (idx) {
        this.get('assets').removeAt(idx);
      },

      removeAssetComponent: function () {
        this.onRemoveAssets();
      },

      selectFiles: function (e) {
        this.onFileSelect(e);
      },

      toggleEmojiPanel: function () {
        this.set('isEmojiPanelOpen', !this.get('isEmojiPanelOpen'));
      }
    }
  });
});