define('clacksnack/mirage/factories/team', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  exports.default = _emberCliMirage.Factory.extend({
    name: _faker.default.company.companyName,
    description: _faker.default.lorem.sentence,
    category(i) {
      if (i % 2 === 1) {
        return _faker.default.company.companyName();
      }
      return null;
    },
    logo(i) {
      if (i > 1) {
        return _faker.default.image.business();
      }
      return null;
    },
    afterCreate(team, server) {
      if (team.employees.length === 0) {
        team.employees = server.schema.employees.find([getRandomNumber(1, 20), getRandomNumber(1, 20), getRandomNumber(1, 20)]);
      }
    }
  });
});