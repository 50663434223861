define('clacksnack/components/comment-panel', ['exports', 'clacksnack/utils/strip-html'], function (exports, _stripHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    intl: Ember.inject.service(),

    classNames: ['comment'],
    classNameBindings: ['IsEditing'],

    comment: null,

    employee: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    isLiked: Ember.computed('comment.likes.[]', function () {
      const event = this.get('comment').get('likes').findBy('employee.id', this.get('employee.id'));
      return !!event;
    }),

    fullTextEdit: Ember.computed('comment.fulltext', function () {
      return this.get('comment.fulltext');
    }),

    isEmptyOrUnchangedEditOrIsSending: Ember.computed('fullTextEdit', 'isSending', function () {
      return this.get('fullTextEdit').length === 0 || this.get('comment.fulltext').trim() === this.get('fullTextEdit').trim() || this.get('isSending') === true;
    }),

    // States
    isEditing: false,
    isSending: false,
    isEmojiPanelOpen: false,

    isCommentByLoggedInUser: Ember.computed('comment.employee', function () {
      const session = this.get('session').get('session');
      const isCommentByLoggedInUser = this.get('comment.employee.id') === session.employeeId;
      return isCommentByLoggedInUser;
    }),

    init() {
      this._super(...arguments);
    },

    submitCommentEdit: function () {
      this.set('isSending', true);
      const fulltext = (0, _stripHtml.stripHTML)(this.get('fullTextEdit'));
      const comment = this.get('comment');
      comment.set('fulltext', fulltext);
      comment.save().then(() => {
        this.set('isEditing', false);
        this.set('isSending', false);
        this.set('isEmojiPanelOpen', false);
      }).catch(() => {
        this.onError();
        this.set('isSending', false);
      });
    },

    onError() {
      const errorMessage = this.get('intl').t('appStatusCode.100');
      this.get('notifications').error(errorMessage, {
        autoClear: true,
        clearDuration: 5000,
        cssClasses: 'notification--error'
      });
    },

    onLike() {
      const event = this.get('store').createRecord('event', {
        eventType: 'like',
        source: this.get('comment'),
        employee: this.get('employee')
      });

      event.save().then(() => {
        // success
      }, () => {
        // error
        event.destroyRecord();
      });
    },

    onUnlike() {
      const event = this.get('comment').get('likes').findBy('employee.id', this.get('employee').get('id'));
      event.destroyRecord();
    },

    actions: {
      cancelEditing: function () {
        this.set("isEditing", false);
      },

      toggleEdit: function () {
        this.set('fullTextEdit', this.get('comment.fulltext'));
        this.set('isEditing', true);
        this.set('isEmojiPanelOpen', false);
      },

      submitEditedComment: function () {
        this.set('isEmojiPanelOpen', false);
        this.submitCommentEdit();
      },

      toggleLike: function () {
        const isLiked = this.get('isLiked');

        if (isLiked) {
          this.onUnlike();
        } else {
          this.onLike();
        }
      },

      toggleEmojiPanel: function () {
        this.set('isEmojiPanelOpen', !this.get('isEmojiPanelOpen'));
      }
    }
  });
});