define('clacksnack/routes/me', ['exports', 'clacksnack/mixins/tracking'], function (exports, _tracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tracking.default, {
    session: Ember.inject.service(),

    model() {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }
  });
});