define('clacksnack/components/setting-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['setting-item'],
    classNameBindings: ['isExpanded'],

    title: null,
    description: null,
    expandable: false,
    isExpanded: false,

    onOpen() {
      this.set('isExpanded', true);
    },

    onClose() {
      this.set('isExpanded', false);
    },

    actions: {
      open: function () {
        this.onOpen();
      },
      close: function () {
        this.onClose();
      }
    }
  });
});