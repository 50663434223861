define('clacksnack/adapters/asset', ['exports', 'clacksnack/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({

        createRecord: function (store, type, snapshot) {
            const post = snapshot.belongsTo('post');
            const data = {};
            const serializer = store.serializerFor(type.modelName);
            const host = this.get('host');
            const namespace = this.get('namespace');
            const url = `${host}/${namespace}/posts/${post.id}/assets`;

            serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

            return this.ajax(url, "POST", { data: data });
        }
    });
});