define('clacksnack/helpers/filter-competences', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterCompetences = filterCompetences;
  function filterCompetences(params /*, hash*/) {
    let competences = params[0];
    let employee = params[1];

    return competences.filter(competence => {
      if (competence.get('employees').indexOf(employee) !== -1) {
        return true;
      }

      return false;
    });
  }

  exports.default = Ember.Helper.helper(filterCompetences);
});