define('clacksnack/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/article.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/article.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/asset.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/asset.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/competence.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/competence.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/employee.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/employee.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/opengraph.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/opengraph.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/organisation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/organisation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/post.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/post.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/project-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/project-state.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project-state.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/search-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/search-item.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/team.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/competence.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/competence.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/location.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/location.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/organisation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/organisation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/competence.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/competence.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/employee.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/employee.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/post.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/post.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/project-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/project-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/search-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/search-item.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/session.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/team.js should pass ESLint\n\n');
  });
});