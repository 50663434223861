define('clacksnack/models/inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inflector = _emberInflector.default.inflector;

  inflector.uncountable('opengraph');

  exports.default = {};
});