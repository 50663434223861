define('clacksnack/components/widget/meeting-room/-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['widget-meeting-room'],
    classNameBindings: ['isExpanded'],
    isExpanded: false,

    actions: {
      toggle: function () {
        this.set('isExpanded', !this.get('isExpanded'));
      }
    }
  });
});