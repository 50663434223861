define('clacksnack/components/asset-renderer/image', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    tagName: 'img',
    attributeBindings: ['src'],
    data: null,
    isThumbnail: false,
    src: null,
    imageSrc: Ember.computed('data', function () {
      if (this.get('data') === null) {
        return null;
      }

      const poster = this.get('data.poster');
      const media = this.get('data.media');

      if (this.get('isThumbnail') === false) {
        return media;
      }

      return poster ? poster : media;
    }),
    didEnterViewport() {
      this.set("src", this.get("imageSrc"));
    }
  });
});