define('clacksnack/controllers/employees/network', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        employees: Ember.inject.controller(),
        filteredEmployees: Ember.computed.alias('employees.filteredEmployees')
    });
});