define('clacksnack/components/comment-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    store: Ember.inject.service(),

    // classNames: ['comment-form'],
    classNameBindings: ['isSending'],

    post: null,
    fulltext: '',

    // States
    isExpanded: false,
    isOpen: false, // Emoji Panel
    isSending: false,

    employee: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    isDisabled: Ember.computed('fulltext', function () {
      return this.get('fulltext').length === 0 || this.get('isSending') === true;
    }),

    autoFocus: Ember.observer('isExpanded', function () {
      const isExpanded = this.get('isExpanded');
      if (!isExpanded) {
        this.$('blur').focus();
      }
    }),

    onError() {
      const errorMessage = this.get('intl').t('appStatusCode.100');
      this.get('notifications').error(errorMessage, {
        autoClear: true,
        clearDuration: 5000,
        cssClasses: 'notification--error'
      });
    },

    actions: {

      submit: function () {
        this.set('isSending', true);
        this.set('isOpen', false);

        const fulltext = this.get('fulltext');
        const store = this.get('store');

        var comment = store.createRecord('comment', {
          fulltext: fulltext,
          postedOn: new Date(),
          employee: this.get('employee'),
          post: this.get('post')
        });

        comment.save().then(() => {
          this.set('fulltext', '');
          this.set('isSending', false);
          this.set('isOpen', false);
        }).catch(() => {
          this.onError();
          this.set('isSending', false);
        });
      },

      toggleEmojiPanel: function () {
        this.set('isOpen', !this.get('isOpen'));
      },

      clear: function () {
        this.set('fulltext', '');
        this.set('isExpanded', false);
      }
    }
  });
});