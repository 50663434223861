define("clacksnack/helpers/presence-to-color", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.presenceToColor = presenceToColor;
  function presenceToColor(params) {
    const colors = {
      "vacation": "blue",
      "home-office": "turquoise",
      "ill": "red",
      "absent": "light-blue"
    };
    const state = params[0];
    return colors[state] || "yellow";
  }

  exports.default = Ember.Helper.helper(presenceToColor);
});