define('clacksnack/services/widget-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        tracking: Ember.inject.service(),

        isOpen: false,

        trackWidgetPanel: Ember.observer('isOpen', function () {
            if (this.get('isOpen')) {
                this.get('tracking').trackEvent('widget_panel', 'open');
            }
        })
    });
});