define('clacksnack/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({

        // Attributes

        name: _emberData.default.attr('string'),
        image: _emberData.default.attr('string'),

        // Relationships

        employees: _emberData.default.hasMany('employee', { async: true }),
        organisation: _emberData.default.hasMany('organisation', { async: true })
    });
});