define('clacksnack/components/widget/_widget-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    classNames: ['widget-content'],
    classNameBindings: ['config.name'],

    config: null,
    data: null,
    interval: 300000,
    isLoading: true,
    timer: null,

    init: function () {
      this._super(...arguments);
      this.load();
    },

    didInsertElement: function () {
      this._super(...arguments);
      const timer = setInterval(() => {
        this.load();
      }, this.get('interval'));
      this.set('timer', timer);
    },

    willDestroyElement() {
      const timer = this.get('timer');
      clearInterval(timer);
      this._super(...arguments);
    },

    load: function () {
      const id = this.get('config').get('id');
      this.get('store').findRecord('widget', id, { reload: true }).then(result => {
        this.set('data', result);
        this.set('isLoading', false);
      });
    },
    submit: function (data) {
      const id = this.get('config').get('id');
      const widget = this.get('store').peekRecord('widget', id);
      widget.set('details', data);
      widget.save();
    }
  });
});