define("clacksnack/mirage/factories/project", ["exports", "ember-cli-mirage", "faker"], function (exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  exports.default = _emberCliMirage.default.Factory.extend({
    title: _faker.default.random.word,
    idea: _faker.default.lorem.paragraph,
    timeplan: _faker.default.date.recent,
    budget() {
      return _faker.default.finance.amount() + " " + _faker.default.finance.currencySymbol();
    },
    teamworkLink: _faker.default.internet.url,
    afterCreate(project, server) {
      const projectOwner = server.schema.employees.find(getRandomNumber(1, 3));
      const chef = server.schema.employees.find(getRandomNumber(1, 3));
      const members = server.schema.employees.find([1, 2, 3]);
      const organisations = server.schema.organisations.all();
      const projectStates = [server.create("project-state", "currentMonth"), server.create("project-state", "lastMonth"), server.create("project-state", "preLastMonth")];
      project.update({
        projectOwner,
        chef,
        members,
        organisations,
        projectStates
      });
    }
  });
});