define('clacksnack/mirage/factories/article', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: _faker.default.random.word,
    url: _faker.default.internet.url
  });
});