define('clacksnack/components/search-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    router: Ember.inject.service('-routing'),
    searchPanel: Ember.inject.service(),

    classNames: ['search'],
    classNameBindings: ['isActive'],

    isActive: Ember.computed.alias('searchPanel.isActive'),

    isClearable: Ember.computed('isActive', function () {
      return !this.get('isActive');
    }),

    placeholder: Ember.computed(function () {
      const number = this.getRandomValue(0, 2);
      const texts = ["Suche nach Themen oder Personen...", "Tipp: Verwende @ für Personen...", "Tipp: Verwende # für Themen..."];

      return texts[number];
    }),

    searchString: null,

    getRandomValue: function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },

    keyDown: function (evt) {
      if (evt.which === 13) {
        this.onEnter();
      }
    },

    onEnter() {
      const searchString = this.get('searchString');
      if (searchString && searchString.length > 0) {
        this.get('router').transitionTo('search', { queryParams: { q: searchString } });
        this.onClear();
      } else {
        this.$('input').focus();
      }
    },

    onClear() {
      this.set('searchString', null);
      this.set('searchPanel.isActive', false);
      this.$('input').blur();
    },

    didInsertElement: function () {
      this._super(...arguments);
      this.$(document).on('keydown', e => {
        if ((e.ctrlKey || e.metaKey) && e.which === 70) {
          e.preventDefault();
          this.set('searchPanel.isActive', true);
        }
        if (e.which == 27) {
          this.onClear();
        }
      });
    },

    willDestroyElement: function () {
      this._super(...arguments);
      this.$(document).off('keydown');
    },

    onActivate: Ember.observer('isActive', function () {
      const isActive = this.get('isActive');
      if (isActive) {
        setTimeout(() => {
          this.$('input').focus();
        }, 50);
      }
    }),

    actions: {
      search: function () {
        this.onEnter();
      },
      deactivate: function () {
        this.onClear();
      }
    }
  });
});