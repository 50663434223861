define('clacksnack/mirage/factories/opengraph', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCliMirage.default.Factory.extend({
        poster: _faker.default.image.city,
        title: _faker.default.lorem.sentence,
        description: _faker.default.lorem.paragraph
    });
});