define('clacksnack/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // Attributes

    mediaType: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    poster: _emberData.default.attr('string'),
    media: _emberData.default.attr('string'),

    // Relationships

    post: _emberData.default.belongsTo('post', { async: true })
  });
});