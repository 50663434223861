define('clacksnack/services/app-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        config: null,

        read: function () {
            let config = {};
            let settings = localStorage.getItem('settings');
            if (settings) {
                config = JSON.parse(settings);
            }

            this.set('config', config);
        },
        write: function () {
            const config = this.get('config');
            localStorage.setItem('settings', JSON.stringify(config));
        },

        init: function () {
            this._super();
            this.set('config', {});
            this.read();
        }
    });
});