define('clacksnack/components/post-form/-upload-animation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['upload-animation'],
        classNameBindings: ['isShown', 'isReady', 'isFinished', 'isSuccess'],
        isShown: false,
        isReady: Ember.computed('status', function () {
            return this.get('status') === 1;
        }),
        isFinished: Ember.computed('status', function () {
            return this.get('status') == 2;
        }),
        isSuccess: Ember.computed('status', function () {
            return this.get('status') === 3;
        }),
        isFailure: Ember.computed('status', function () {
            return this.get('status') === -1;
        }),

        labels: null,
        status: 0,
        items: 0,
        index: 0,

        init: function () {
            this._super(...arguments);
            this.set('labels', ['Erstelle Beitrag', 'Lade Dokument {{index}} von {{items}}', 'Veröffentliche Beitrag', '']);
        },

        currentLabel: Ember.computed('status', 'index', function () {
            const status = this.get('status');
            const labels = this.get('labels');
            return labels[status].replace(/{{\s*[\w.]+\s*}}/g, placeholder => {
                return this.get(placeholder.substring(2, placeholder.length - 2));
            });
        })
    });
});