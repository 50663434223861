define('clacksnack/components/textarea-autosize', ['exports', 'ember-cli-textarea-autosize/components/textarea-autosize'], function (exports, _textareaAutosize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _textareaAutosize.default;
    }
  });
});