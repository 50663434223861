define('clacksnack/components/post-panel/-toolbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['post__toolbar'],
    classNameBindings: ['isExpanded', 'isLiked'],

    session: Ember.inject.service(),
    store: Ember.inject.service(),

    post: null,

    // States
    isExpanded: false,

    employee: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    isLiked: Ember.computed('post.likes.[]', function () {
      const event = this.get('post').get('likes').findBy('employee.id', this.get('employee').get('id'));
      return !!event;
    }),

    init() {
      this._super(...arguments);
    },

    onLike: function () {
      const event = this.get('store').createRecord('event', {
        eventType: 'like',
        source: this.get('post'),
        employee: this.get('employee')
      });
      event.save().then(() => {}, () => {
        // error message here
        event.destroyRecord();
      });
    },

    onUnlike: function () {
      const event = this.get('post').get('likes').findBy('employee.id', this.get('employee').get('id'));
      event.destroyRecord();
    },

    actions: {
      toggleCommentPanel: function () {
        this.set('isExpanded', !this.get('isExpanded'));
      },

      toggleLike: function () {
        if (!this.get('isLiked')) {
          this.onLike();
        } else {
          this.onUnlike();
        }
      }
    }
  });
});