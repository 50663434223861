define("clacksnack/locales/de/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "post": {
      "written-by": "von <span class='abbreviation'>{{writtenBy}}</span>",
      "read-more": "Weiterlesen"
    },
    "comment": {
      "submit": "Posten",
      "edit": "Editieren",
      "cancel": "Abbrechen",
      "update": "Aktualisieren"
    },
    "upload": {
      "labels": ""
    }
  };
});