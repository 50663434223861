define('clacksnack/services/user', ['exports', 'clacksnack/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    host: _environment.default.APP.REST_HOST,
    namespace: _environment.default.APP.REST_NAMESPACE,

    changePassword(payload) {
      const session = JSON.parse(localStorage.getItem('session'));
      const url = `${this.get('host')}/${this.get('namespace')}/password/change`;
      return Ember.$.ajax({
        url: url,
        method: "PATCH",
        contentType: 'application/json',
        headers: {
          'x-auth-token': session.token
        },
        data: JSON.stringify(payload)
      });
    }
  });
});