define('clacksnack/components/widget/meeting-room', ['exports', 'clacksnack/components/widget/_widget-content'], function (exports, _widgetContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetContent.default.extend({

    toggleList: function (roomId) {
      const container = Ember.$.find(`[data-room-id="${roomId}"`);
      container.toggleClass('is-expanded');
    },

    actions: {

      toggle: function (id) {
        this.toggleList(id);
      }
    }
  });
});