define('clacksnack/adapters/session', ['exports', 'ember-data', 'clacksnack/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.APP.REST_HOST,
    namespace: _environment.default.APP.REST_NAMESPACE,

    createRecord: function (store, type, snapshot) {
      let data = {
        email: snapshot.record.email,
        password: snapshot.record.password
      };
      // let serializer = store.serializerFor(type.modelName);
      let url = this.get('host') + '/' + this.get('namespace') + '/sessions';
      // serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(url, 'POST', { data: data });
    }
  });
});