define('clacksnack/components/widget-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['widget'],
        classNameBindings: ['isExpanded'],

        title: null,

        isMandatory: false,
        isExpanded: false,

        didInsertElement: function () {
            this._super(...arguments);
            this.set('isExpanded', this.get('isMandatory'));
        },

        actions: {
            toggle: function () {
                this.set('isExpanded', !this.get('isExpanded'));
            }
        }
    });
});