define('clacksnack/components/shared/modal-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['modal'],
    classNameBindings: ['isOpen'],

    // Properties

    title: null,
    confirmLabel: null,
    cancelLabel: null,

    // States

    isOpen: false,

    // Event Hooks
    onCancel: function () {},
    onSubmit: function () {},

    // Actions

    actions: {
      cancel: function () {
        this.set('isOpen', false);
        this.onCancel();
      },

      submit: function () {
        this.set('isOpen', false);
        this.onSubmit();
      }
    }
  });
});