define("clacksnack/components/project-radar", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,
    numberOfPhases: 6,
    selectedSliderDate: null,

    getStateWithDate(project) {
      const selectedDate = this.get("selectedSliderDate");
      // end of month
      const lastOfMonth = (0, _moment.default)(selectedDate).endOf("month");
      // sort states
      const states = project.get("projectStates").toArray().sort((a, b) => {
        let dateA = a.get("date");
        let dateB = b.get("date");
        if ((0, _moment.default)(dateA).isBefore(dateB)) {
          return -1;
        }
        return 1;
      });
      const filteredStates = states.filter(state => {
        return (0, _moment.default)(state.get("date")).isSameOrBefore(lastOfMonth);
      });

      if (filteredStates.length === 0) {
        return null;
      }
      return filteredStates.pop();
    },

    // computed property
    processedData: Ember.computed("data.@each", "selectedSliderDate", function () {
      let data = this.get("data");
      let processedData = [];

      if (this.get("selectedSliderDate") === null) {
        return [];
      }

      // variables needed for the positioning calcualation
      const innerLaneRadius = 9.05; // in percent
      const laneRadius = 6.87; // in percent
      const amountOfCategories = data.get("length");

      data.forEach((category, categoryNumber) => {
        const projects = category.get("projects");

        // key => phase number, value => number of projects in this phase
        const projectPerPhaseStore = [];

        /* following loop determines total number of projects within a phase */
        projects.forEach(project => {
          const currentState = this.getStateWithDate(project);

          if (!currentState) {
            return;
          }

          const phaseNumber = currentState.get("phaseNumber");

          // initialize array for this phase
          if (projectPerPhaseStore[phaseNumber] === undefined) {
            projectPerPhaseStore[phaseNumber] = 0;
          }

          // count projects for this phase up
          projectPerPhaseStore[phaseNumber]++;
        });

        // key => phase number, value => index of current project in this phase
        const currentProjectInPhase = [];

        /* following loop sets a currentState property, an appropriate color and a postion (x,y)
                  to a project according to its position (index) within a phaselane */

        projects.forEach(project => {
          const currentState = this.getStateWithDate(project);

          if (!currentState) {
            return;
          }
          // set currentState as property to project
          project.set("currentState", currentState);
          // set appropriate color to project
          project = this.setColorToProject(project, categoryNumber);

          const phaseNumber = currentState.get("phaseNumber");

          // initialize array for this phase
          if (currentProjectInPhase[phaseNumber] === undefined) {
            currentProjectInPhase[phaseNumber] = 0;
          }

          // count projects for this phase up
          currentProjectInPhase[phaseNumber]++;

          // calculate radius in percent
          const radius = innerLaneRadius + phaseNumber * laneRadius;

          // calculate angle
          const angleOfSegment = 180 / amountOfCategories;
          const totalOfProjectsInPhase = projectPerPhaseStore[phaseNumber];
          const indexOfProjectInPhase = currentProjectInPhase[phaseNumber];
          const baseAngleForCategory = categoryNumber * angleOfSegment;
          const angleOfProjectInPhase = angleOfSegment / (totalOfProjectsInPhase + 1) * indexOfProjectInPhase;
          const angle = baseAngleForCategory + angleOfProjectInPhase;

          // transform polar coordinates into cartesian coordinates
          const x = radius * Math.cos(angle * Math.PI / 180);
          const y = radius * Math.sin(angle * Math.PI / 180);

          // 50 must be added because the spot need 50% offset to align
          // centered in diagram
          project.set("positionLeft", x + 50);

          // must be multiplied by 2 because the image has half heigth of image width
          // and the percentual radius is based on width
          project.set("positionBottom", y * 2);
          processedData.push(project);
        });
      });

      return processedData;
    }),

    setColorToProject(project, categoryNumber) {
      if (categoryNumber === 0) {
        project.color = "#004B87";
        project.set("tooltipIsInverse", true);
      }

      if (categoryNumber === 1) {
        project.color = "#B1C346";
        project.set("tooltipIsInverse", true);
      }

      if (categoryNumber === 2) {
        project.color = "#C4272E";
      }

      if (categoryNumber === 3) {
        project.color = "#009AA7";
      }

      if (categoryNumber === 4) {
        project.color = "#EEA816";
      }

      return project;
    },

    actions: {
      updateSlider(selectedDate) {
        this.set("selectedSliderDate", selectedDate);
      }
    }
  });
});