define('clacksnack/components/post-panel', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {

    tagName: 'article',
    classNames: ['post-panel'],

    classNameBindings: ['isEditing'],

    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    post: null,
    interval: null,

    // States
    isEditing: false,
    isDetailed: false,
    isExpanded: false,
    // isSending: false,
    // isEmojiPanelOpen: false,

    characterLimit: Ember.computed('isDetailed', function () {
      if (!this.get('isDetailed')) {
        return 200;
      }
      return null;
    }),

    // fullTextEdit: computed('post.fulltext', function() {
    //   return this.get('post.fulltext');
    // }),

    // isEmptyOrUnchangedEditOrIsSending: computed('fullTextEdit', 'isSending', function() {
    //   return (this.get('fullTextEdit').length === 0) || (this.get('post.fulltext').trim() === this.get('fullTextEdit').trim()) || (this.get('isSending') === true);
    // }),

    init() {
      this._super(...arguments);
      this.setProperties({
        viewportSpy: true
      });
    },

    setUpdateInterval: function () {
      const interval = setInterval(() => {
        const id = this.get('post').get('id');
        this.get('store').findRecord('post', id).then(post => {
          this.set('post', post);
        });
      }, 60000); // Move to config
      this.set('interval', interval);
    },

    clearUpdateInterval: function () {
      const interval = this.get('interval');
      clearInterval(interval);
      this.set('interval', null);
    },

    didEnterViewport() {
      this.setUpdateInterval();
    },

    // Causes error on destroy
    didExitViewport() {
      this.clearUpdateInterval();
    },

    willDestroyElement() {
      this._super(...arguments);
      this.clearUpdateInterval();
    },

    // submitPostEdit: function() {
    //   this.set('isSending', true);
    //   const post = this.get('post');
    //   post.set('fulltext', this.get('fullTextEdit'));
    //   post.save().then(() => {
    //     this.set('isEditing', false);
    //     this.set('isSending', false);
    //     this.set('isEmojiPanelOpen', false);
    //   }).catch(() => {
    //     this.onError();
    //     this.set('isSending', false);
    //   });
    // },

    // onError() {
    //   const errorMessage = this.get('intl').t('appStatusCode.100');
    //   this.get('notifications').error(errorMessage, {
    //     autoClear: true,
    //     clearDuration: 5000,
    //     cssClasses: 'notification--error'
    //   });
    // },

    actions: {

      // submitEditedPost: function() {
      //   this.set('isEmojiPanelOpen', false);
      //   this.submitPostEdit();
      // },

      enableEditing: function () {
        // this.set('fullTextEdit', this.get('post.fulltext'));
        this.set('isEditing', true);
        // this.set('isEmojiPanelOpen', false);
      }

      // toggleEmojiPanel: function() {
      //   this.set('isEmojiPanelOpen', !this.get('isEmojiPanelOpen'));
      // }
    }
  });
});