define('clacksnack/components/employee-network', ['exports', 'vis-network', 'clacksnack/utils/colors'], function (exports, _visNetwork, _colors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    router: Ember.inject.service('-routing'),

    classNames: ['employee-network'],

    data: null,
    network: null,

    didInsertElement() {
      this._super(...arguments);
      const container = this.$()[0];
      const data = this.getData();
      const options = this.getOptions();
      const network = new _visNetwork.default.Network(container, data, options);
      network.on('click', properties => {
        if (properties.nodes.length > 0) {
          const id = properties.nodes[0];
          this.get('router').transitionTo('employee', id);
        }
      });
      this.set('network', network);
    },

    didUpdateAttrs() {
      this._super(...arguments);
      let network = this.get('network');
      network.setData(this.getData());
    },

    willDestroyElement() {
      this._super(...arguments);
      this.get('network').destroy();
    },

    getOptions() {
      return {
        height: '100%',
        nodes: {
          borderWidth: 3,
          size: 30,
          color: {
            border: "#222222",
            background: "#666666"
          },
          font: {
            color: "#000000",
            face: "'Open Sans', sans-serif"
          }
        },
        edges: {
          width: 2,
          color: "gray"
        },
        interaction: {
          hover: true
        }
      };
    },

    getData() {

      const filteredEmployees = this.get('data').toArray();
      const relationships = [];

      const nodes = filteredEmployees.map(employee => {
        const brandColor = employee.get("organisation").get("brandColor");
        return {
          id: employee.id,
          shape: 'circularImage',
          image: employee.avatar,
          brokenImage: employee.avatar,
          label: employee.fullname,
          color: {
            hover: _colors.default[brandColor],
            border: _colors.default[brandColor]
          }
        };
      });

      filteredEmployees.forEach(employee => {
        const brandColor = employee.get("organisation").get("brandColor");
        const managedBy = employee.get("managedBy").get("id");
        if (managedBy) {
          relationships.push({
            from: managedBy,
            to: employee.id,
            arrows: {
              from: {
                enabled: true,
                type: "inv_triangle"
              }
            },
            color: _colors.default[brandColor]
          });
        }
        const supervisedBy = employee.get("supervisedBy").get("id");
        if (supervisedBy) {
          relationships.push({
            from: supervisedBy,
            to: employee.id,
            arrows: {
              from: {
                enabled: true,
                type: "inv_triangle"
              }
            },
            color: _colors.default[brandColor],
            dashes: true
          });
        }
      });

      return {
        nodes: nodes,
        edges: relationships
      };
    }
  });
});