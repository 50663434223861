define('clacksnack/components/asset-editor/link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        data: null,

        actions: {
            removePoster: function () {
                this.get('data').get('firstObject').set('poster', null);
            },

            removeAsset: function (index) {
                this.removeItem(index);
            }
        }
    });
});