define('clacksnack/models/project-category', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),

        //Relationships
        projects: _emberData.default.hasMany('project')
    });
});