define('clacksnack/serializers/-default', ['exports', '@ember-data/serializer/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _json.default;
    }
  });
});