define("clacksnack/components/shared/input-password", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ["password"],
        inputType: "password",

        actions: {
            showPassword: function () {
                this.set('inputType', "text");
            },
            hidePassword: function () {
                this.set('inputType', "password");
            }
        }
    });
});