define('clacksnack/components/shared/extended-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextArea.extend({
    onPaste: null,
    didInsertElement: function () {
      this._super(...arguments);
      var self = this;
      this.$().on("paste", function (event) {
        setTimeout(function () {
          self.sendAction(self.onPaste, self.$().val(), event);
        }, 100);
      });
    },
    willDestroyElement: function () {
      this._super(...arguments);
      this.$().off('paste');
    }
  });
});