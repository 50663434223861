define('clacksnack/components/widget/u-already-know', ['exports', 'clacksnack/components/widget/_widget-content'], function (exports, _widgetContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _widgetContent.default.extend({
    interval: 900000,
    characterLimit: 200,
    isTextExpanded: false,

    color: Ember.computed('data.details.organisation', function () {
      if (this.get('data.details.organisation') === 'Lachs von Achtern') {
        return 'yellow';
      }
      return 'light-blue';
    }),

    hasText: Ember.computed('data.details.text', function () {
      return this.get('data.details.text') !== null;
    }),

    text: Ember.computed('data.details.text', 'isTextExpanded', function () {
      if (this.get('isTextExpanded')) {
        return this.get('data.details.text');
      }

      var characterLimit = this.get('characterLimit');
      var fulltext = this.get('data.details.text');

      if (!characterLimit || !fulltext) {
        return fulltext;
      }

      var numberOfCharacter = 0;
      var allowedWords = [];
      var words = fulltext.split(' ');

      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        numberOfCharacter = numberOfCharacter + word.length;
        if (numberOfCharacter < characterLimit) {
          allowedWords.push(word);
        }
      }

      if (fulltext.length > characterLimit) {
        allowedWords.push('...');
      }

      var shortenedText = allowedWords.join(' ');

      return shortenedText;
    }),

    isShortened: Ember.computed('data.details.text', function () {
      return this.get('data.details.text') && this.get('data.details.text').length > this.get('characterLimit');
    }),

    actions: {
      showMore() {
        this.set('isTextExpanded', true);
      },

      showLess() {
        this.set('isTextExpanded', false);
      },

      refresh() {
        this.set('isLoading', true);
        this.load();
      }
    }
  });
});