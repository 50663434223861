define('clacksnack/components/post-panel/-header', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        session: Ember.inject.service(),

        tagName: 'header',
        classNames: ['post__header'],

        post: null,
        today: true,

        interval: null,

        isPostByLoggedInUser: Ember.computed('post', function () {
            const session = this.get('session').get('session');
            return this.get('post.employee.id') === session.employeeId;
        }),

        init: function () {

            this._super(...arguments);

            const post = this.get('post');
            const date = post.get('postedOn');
            const diff = (0, _moment.default)().diff((0, _moment.default)(date));
            if (diff > 1000 * 60 * 60 * 24) {
                this.set('today', false);
            }

            const interval = setInterval(() => {
                const post = this.get('post');
                const date = post.get('postedOn');
                const diff = (0, _moment.default)().diff((0, _moment.default)(date));
                if (diff > 1000 * 60 * 60 * 24) {
                    this.set('today', false);
                }
            }, 1000);

            this.set('interval', interval);
        },

        willDestroyElement: function () {
            this._super(...arguments);
            clearInterval(this.get("interval"));
        }
    });
});