define('clacksnack/mirage/factories/competence', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    description: _faker.default.random.words,
    icon: _faker.default.random.word,
    title: _faker.default.random.word
  });
});