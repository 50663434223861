define('clacksnack/controllers/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: Ember.inject.service('app-settings'),
    userService: Ember.inject.service('user'),

    showChangePasswordForm: false,

    onChange: function () {
      this.get('settings').write();

      // manually invoke property observer
      this.get('settings').notifyPropertyChange('config');
    },
    actions: {
      showChangePasswordForm: function () {
        this.set('showChangePasswordForm', true);
      },
      change: function () {
        this.onChange();
      }
    }
  });
});