define('clacksnack/components/shared/carousel-slider', ['exports', 'swiper'], function (exports, _swiper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['swiper-container'],

        params: null,
        config: null,
        instance: null,

        init: function () {
            this._super(...arguments);
            const defaults = {
                direction: 'horizontal',
                loop: false
            };
            const params = this.get('params');
            const config = Ember.assign(defaults, params);
            this.set('config', config);
        },

        initSlider: function () {
            let instance = this.get('instance');
            if (!instance) {
                Ember.$(this.get('element')).children('.swiper-wrapper').children().addClass('swiper-slide');
                instance = new _swiper.default('.swiper-container', this.get('options'));
                this.set('instance', instance);
            }
        },

        destroySlider: function () {
            let instance = this.get('instance');
            if (instance) {
                instance.destroy();
                this.set('instance', null);
            }
        },

        didRender: function () {
            this._super(...arguments);
            this.initSlider();
        },
        willRender: function () {
            this.destroySlider();
            this._super(...arguments);
        }
    });
});