define("clacksnack/utils/strip-html", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function stripHTML(html) {

    if (html === undefined || html === null || html === "") {
      return "";
    }

    const element = document.createElement("div");
    element.innerHTML = html;
    return element.textContent || element.innerText;
  }

  exports.stripHTML = stripHTML;
});