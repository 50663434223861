define('clacksnack/components/asset-editor/image', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['assets-editor', 'assets-editor--image'],

        actions: {
            addItem: function () {
                this.get('addItem')();
            },
            removeAsset: function (index) {
                this.removeItem(index);
            }
        }
    });
});