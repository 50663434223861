define('clacksnack/models/post', ['exports', 'ember-data', 'clacksnack/models/event-source'], function (exports, _emberData, _eventSource) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _eventSource.default.extend({

		// Attributes

		fulltext: _emberData.default.attr('string'),
		postedOn: _emberData.default.attr('date'),
		isPublished: _emberData.default.attr('boolean'),

		// Relationships

		assets: _emberData.default.hasMany('asset', { async: true }),
		employee: _emberData.default.belongsTo('employee', { async: true }),
		comments: _emberData.default.hasMany('comment', { async: true }),

		likes: Ember.computed('events.[]', function () {
			return this.get('events').filterBy('eventType', 'like');
		})
	});
});