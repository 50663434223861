define('clacksnack/helpers/socify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),
    compute(params) {

      const router = this.get('router');

      let text = params[0];

      if (text === undefined || text === null || text === '') {
        return Ember.String.htmlSafe('');
      }

      const dictionary = [{
        pattern: /(^|\s)(#[a-z0-9äöüÄÖÜß\d-]+)/ig,
        replace: function (match, p1, p2) {
          const url = router.urlFor('search', { queryParams: { q: p2 } });
          return `${p1}<a href="${url}" class="hashtag">${p2}</a>`;
        }
      }, {
        pattern: /(^|\s)(@[a-zöüä\d-]+)/ig,
        replace: function (match, p1, p2) {
          const url = router.urlFor('search', { queryParams: { q: p2 } });
          return `${p1}<a href="${url}" class="mention">${p2}</a>`;
        }
      }, {
        pattern: /(^|\s)(\+[a-z\d-]+)/ig,
        replace: function (match, p1, p2) {
          const url = router.urlFor('search', { queryParams: { q: p2 } });
          return `${p1}<a href="${url}" class="competence">${p2}</a>`;
        }
      }];

      for (var i = 0; i < dictionary.length; i++) {
        var search = dictionary[i];
        text = text.replace(search.pattern, search.replace);
      }

      return Ember.String.htmlSafe(text);
    }
  });
});