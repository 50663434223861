define("clacksnack/utils/colors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const colors = {
    "light-blue": "#00a3e0",
    "yellow": "#eea816"
  };

  exports.default = colors;
});