define('clacksnack/adapters/application', ['exports', 'ember-data', 'clacksnack/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.APP.REST_HOST,
    namespace: _environment.default.APP.REST_NAMESPACE,
    headers: Ember.computed(function () {

      const session = localStorage.getItem('session');
      let token = '';

      if (session) {
        token = JSON.parse(localStorage.getItem('session')).token;
      }

      return {
        'x-auth-token': token || null
      };
    }).volatile() // use the volatile function to set the property into a non-cached mode causing the headers to be recomputed with every request.
  });
});