define('clacksnack/routes/settings', ['exports', 'clacksnack/mixins/tracking'], function (exports, _tracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tracking.default, {
    session: Ember.inject.service(),
    actions: {
      logout: function () {
        this.get('session').logout();
      }
    }
  });
});