define('clacksnack/components/comment-panel/-avatar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['avatar'],

    avatar: Ember.computed('employee.avatar', function () {
      let avatar = this.get('employee.avatar');
      if (Ember.isPresent(avatar)) {
        return avatar;
      } else {
        return this.get('employee.logo');
      }
    }),
    employee: null
  });
});