define('clacksnack/mirage/factories/post', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  exports.default = _emberCliMirage.Factory.extend({
    fulltext: _faker.default.lorem.text,
    postedOn: i => {
      // 1 day difference for every new post
      const diff = i * 86400000;
      const now = Date.now();
      const date = new Date(now - diff);
      return date.toISOString();
    },
    isPublished: true,
    afterCreate(post, server) {
      const employee = server.schema.employees.find(getRandomNumber(1, 3));
      if (!post.employee) {
        post.update({
          employee
        });
      }
      if (post.comments.length === 0) {
        post.comments = server.createList('comment', getRandomNumber(1, 8), {
          post
        });
      }
      if (post.events.length === 0) {
        post.events = server.createList('event', getRandomNumber(1, 5), {
          source: post
        });
      }
    },
    withLink: (0, _emberCliMirage.trait)({
      afterCreate(post, server) {
        if (post.assets.length === 0) {
          post.assets = server.create('asset', 'isLink', {
            post
          });
        }
      }
    }),
    withImage: (0, _emberCliMirage.trait)({
      afterCreate(post, server) {
        if (post.assets.length === 0) {
          post.assets = server.createList('asset', 1, {
            post
          });
        }
      }
    }),
    withImageGallery: (0, _emberCliMirage.trait)({
      afterCreate(post, server) {
        if (post.assets.length === 0) {
          post.assets = server.createList('asset', 4, {
            post
          });
        }
      }
    }),
    withEmbeddedVideo: (0, _emberCliMirage.trait)({
      afterCreate(post, server) {
        if (post.assets.length === 0) {
          post.assets = server.create('asset', 'isEmbeddedVideo', {
            post
          });
        }
      }
    }),
    isFresh: (0, _emberCliMirage.trait)({
      postedOn: () => {
        const now = Date.now();
        const date = new Date(now);
        return date.toISOString();
      }
    })
  });
});