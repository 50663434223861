define('clacksnack/components/emoji-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['emoji-panel'],
        classNameBindings: ['isOpen'],
        isOpen: false,
        text: null,
        emojis: null,

        init: function () {
            this._super(...arguments);
            this.set('emojis', ['😇', '😈', '👿', '😉', '😊', '😋', '😌', '😍', '😎', '😏', '😐', '😑', '😒', '😓', '😔', '😕', '😖', '😗', '😘', '😙', '😚', '😛', '😜', '😝', '😞', '😟', '😠', '😡', '😢', '😣', '😤', '😥', '😦', '😧', '😨', '😩', '😪', '😫', '😬', '😭', '😮', '😯', '😰', '😱', '😲', '😳', '😴', '😵', '😶', '🙁', '🙂', '💩']);
        },
        actions: {
            addEmoji: function (emoji) {
                this.set('text', this.get('text') + emoji);
            }
        }
    });
});