define('clacksnack/components/navigation-panel/-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['navigation__item'],
        route: null,
        icon: null
    });
});