define('clacksnack/adapters/employee', ['exports', 'clacksnack/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      const id = snapshot.id;
      let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (snapshot.adapterOptions) {
        url += '?';
        for (const option in snapshot.adapterOptions) {
          url += `${option}=${snapshot.adapterOptions[option]}&`;
        }

        url = url.substr(0, url.length - 1);
      }

      return this.ajax(url, 'PATCH', { data: data });
    }
  });
});