define('clacksnack/helpers/linkify', ['exports', 'clacksnack/utils/url-regex'], function (exports, _urlRegex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linkify = linkify;


  const ALLOWED_ATTRIBUTE_NAMES = ['rel', 'class'];

  function linkify(params, options) {
    let textToLinkify = params[0];
    const windowTarget = params[1] || "_self";
    const sharedAttributes = opts2attrs(options);

    textToLinkify = textToLinkify.replace((0, _urlRegex.urlRegex)(), function (s) {
      let url;
      let displayText = s.trim();

      if (s.trim().match(/^www\./ig)) {
        if (options && options.defaultScheme) {
          url = options.defaultScheme + '://' + s.trim();
        } else {
          url = '//' + s.trim();
        }
      } else {
        url = s.trim();
      }

      return `<a href="${url}" target="${windowTarget}"${sharedAttributes}>${displayText}</a>`;
    });

    return textToLinkify;
  }

  exports.default = Ember.Helper.helper(linkify);


  function opts2attrs(options) {
    const stringOfAttributes = [''];

    if (Ember.typeOf(options) === 'object') {
      for (let i = 0; i < ALLOWED_ATTRIBUTE_NAMES.length; i++) {
        const attributeName = ALLOWED_ATTRIBUTE_NAMES[i];
        if (attributeName in options) {
          stringOfAttributes.push(`${attributeName}="${options[attributeName]}"`);
        }
      }
    }

    return stringOfAttributes.join(' ');
  }
});