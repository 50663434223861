define('clacksnack/components/user-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // user - user model | component parameter

    store: Ember.inject.service(),

    userCompetences: null,
    isEditable: true,

    competences: Ember.computed(function () {
      return this.get('store').findAll('competence');
    }),

    init() {
      this._super(...arguments);
      this.reset();
    },

    reset() {
      this.get('user').rollbackAttributes();
      this.set('userCompetences', this.get('user.competences'));
    },

    onClose() {
      this.reset();
    },

    onSave() {
      this.set('user.competences', this.get('userCompetences'));
      this.get('user').save();
    },

    actions: {
      close() {
        this.onClose();
      },
      save() {
        this.onSave();
      }
    }
  });
});