define("clacksnack/mirage/factories/project-category", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    title(i) {
      let array = ["Organisation", "Veranstaltungen", "Tools", "Kommunikation", "Wissen"];
      return array[i];
    },
    afterCreate(category, server) {
      // console.log(category.attrs.id); if (categories.attrs.id === 1) {}
      const projects = server.createList("project", 3);
      category.update({
        projects
      });
    }
  });
});