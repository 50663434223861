define('clacksnack/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    intl: Ember.inject.service(),
    // moment: inject(),
    beforeModel() {
      // this.get('moment').setLocale('de');
      this.intl.setLocale(['de-de']);
    }
  });
});