define('clacksnack/initializers/viewport-config', ['exports', 'ember-in-viewport/initializers/viewport-config'], function (exports, _viewportConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _viewportConfig.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _viewportConfig.initialize;
    }
  });
});