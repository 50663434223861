define('clacksnack/components/post-panel/-content', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['post__content'],

        characterLimit: null,
        fulltext: null,

        shortenedText: Ember.computed('fulltext', 'characterLimit', function () {

            var characterLimit = this.get('characterLimit');
            var fulltext = this.get('fulltext');

            if (!characterLimit || !fulltext) {
                return fulltext;
            }

            var numberOfCharacter = 0;
            var allowedWords = [];
            var words = fulltext.split(' ');

            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                numberOfCharacter = numberOfCharacter + word.length;
                if (numberOfCharacter < characterLimit) {
                    allowedWords.push(word);
                }
            }

            if (fulltext.length > characterLimit) {
                allowedWords.push('...');
            }

            var shortenedText = allowedWords.join(' ');

            return shortenedText;
        }),

        isShortened: Ember.computed('fulltext', 'characterLimit', function () {
            return this.get('characterLimit') && this.get('fulltext') && this.get('fulltext').length > this.get('characterLimit');
        }),

        actions: {
            showFulltext: function () {
                this.set("characterLimit", null);
            }
        }
    });
});