define('clacksnack/mirage/factories/employee', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    prename: _faker.default.name.firstName,
    surname: _faker.default.name.lastName,
    abbreviation: _faker.default.hacker.abbreviation,
    avatar: _faker.default.internet.avatar,
    birthday: _faker.default.date.past,
    email: _faker.default.internet.email,
    skype: _faker.default.internet.userName,
    phone: _faker.default.phone.phoneNumber,
    mobile: _faker.default.phone.phoneNumber,
    summary: function () {
      return _faker.default.lorem.paragraph() + '\n\n' + _faker.default.lorem.sentence();
    },
    isActive: _faker.default.random.boolean,
    jobTitle: _faker.default.name.jobTitle,

    afterCreate(employee, server) {
      if (employee.competences.length === 0) {
        employee.update({
          competences: server.schema.competences.all()
        });
      }
      if (employee.roles.length === 0) {
        employee.update({
          roles: server.schema.roles.all()
        });
      }
    },

    ClickSolutions: (0, _emberCliMirage.trait)({
      afterCreate(employee, server) {
        if (!employee.organisation) {
          employee.update({
            organisation: server.schema.organisations.findBy({ abbreviation: 'cs' })
          });
        }
      }
    }),

    LachsVonAchtern: (0, _emberCliMirage.trait)({
      afterCreate(employee, server) {
        if (!employee.organisation) {
          employee.update({
            organisation: server.schema.organisations.findBy({ abbreviation: 'LvA' })
          });
        }
      }
    }),

    Wismar: (0, _emberCliMirage.trait)({
      afterCreate(employee, server) {
        if (!employee.location) {
          employee.update({
            location: server.schema.locations.find(1)
          });
        }
      }
    }),

    Berlin: (0, _emberCliMirage.trait)({
      afterCreate(employee, server) {
        if (!employee.location) {
          employee.update({
            location: server.schema.locations.find(2)
          });
        }
      }
    }),

    Hamburg: (0, _emberCliMirage.trait)({
      afterCreate(employee, server) {
        if (!employee.location) {
          employee.update({
            location: server.schema.locations.find(3)
          });
        }
      }
    })
  });
});