define('clacksnack/mirage/factories/project-state', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _faker.default.random.word,
    date() {
      return new Date();
    },
    phaseNumber() {
      return _faker.default.random.number({ min: 0, max: 5 });
    },

    currentMonth: (0, _emberCliMirage.trait)({
      afterCreate(projectState /*, server*/) {
        projectState.update({
          date: new Date()
        });
      }
    }),

    lastMonth: (0, _emberCliMirage.trait)({
      afterCreate(projectState /*, server*/) {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        projectState.update({
          date: date
        });
      }
    }),

    preLastMonth: (0, _emberCliMirage.trait)({
      afterCreate(projectState /*, server*/) {
        let date = new Date();
        date.setMonth(date.getMonth() - 2);
        projectState.update({
          date: date
        });
      }
    })

  });
});