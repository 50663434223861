define('clacksnack/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    //Attributes
    eventType: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('date'),

    // Relationships
    employee: _emberData.default.belongsTo('employee', { async: true }),
    source: _emberData.default.belongsTo('event-source', { polymorphic: true, async: true })
  });
});