define('clacksnack/models/search-item', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes

		itemType: _emberData.default.attr('string'),

		// Relationships

		post: _emberData.default.belongsTo('post'),
		employee: _emberData.default.belongsTo('employee')
	});
});