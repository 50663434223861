define('clacksnack/components/user-profile/-avatar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    session: Ember.inject.service(),

    classNames: ['user-profile__avatar'],

    employee: null,
    isProfileImageModalOpen: false,
    isEditable: false,
    customImage: null,

    //
    onFileSelect: function (e) {

      var self = this;
      var selectedFiles = e.target.files;

      if (selectedFiles.length > 0) {
        for (var i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const reader = new FileReader();
          reader.onload = function (e) {

            self.set('customImage', e.target.result);
          };

          reader.readAsDataURL(file);
        }
      }
    },

    onSubmit: function () {
      const employee = this.get('employee');
      const customImage = this.get('customImage');

      employee.set('avatar', customImage);
      employee.save({ adapterOptions: { avatarIncluded: true } }).then(() => {
        this.onClear();
      });
    },

    onClear: function () {
      this.set('customImage', null);
    },

    actions: {

      openProfileImageModal: function () {
        this.set('isProfileImageModalOpen', true);
      },

      submitProfileImageModal: function () {
        this.onSubmit();
      },

      cancelProfileImageModal: function () {
        this.onClear();
      },

      selectFiles: function (e) {
        this.onFileSelect(e);
      }
    }
  });
});