define('clacksnack/components/shared/file-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['file-selector'],

    // States

    disabled: false,

    onChange: function () {},

    actions: {
      onchange: function (e) {
        this.onChange(e);
      }
    }
  });
});