define("clacksnack/components/app-switcher", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    globalConfig: Ember.inject.service(),
    environment: Ember.inject.service(),

    classNames: "app-switcher",
    classNameBindings: "isExpanded",

    isExpanded: false,

    clickEvent: null,

    init() {
      this._super(arguments);
      this.set("clickEvent", this.get("environment").has("touch") ? "touchstart" : "click");
    },

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener(this.get("clickEvent"), e => {
        if (this.get("isExpanded") && !e.target.closest(".app-switcher")) {
          this.set("isExpanded", false);
        }
      });
    },

    willDestroyElementn() {
      this._super(...arguments);
      document.removeEventListener(this.get("clickEvent"), null);
    },

    click(e) {
      const element = e.target.closest('.app-switcher__item');
      if (element.hasClass('app-switcher__item')) {
        this.set("isExpanded", false);
      }
    },

    actions: {
      toggle: function () {
        const isExpanded = this.get("isExpanded");
        this.set("isExpanded", !isExpanded);
      }
    }
  });
});