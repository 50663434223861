define('clacksnack/router', ['exports', 'clacksnack/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('protected', { path: '/' }, function () {
      this.route('posts', { path: '/posts', resetNamespace: true }, function () {
        this.route('post', { path: '/:id', resetNamespace: true });
      });

      this.route('me', { path: '/me', resetNamespace: true });

      this.route('employees', { path: '/employees', resetNamespace: true }, function () {
        this.route('list', { path: '/list' });
        this.route('network', { path: '/network' });
      });

      this.route('employee', { path: '/employees/:id', resetNamespace: true });

      this.route('teams', { path: '/teams', resetNamespace: true });

      this.route('settings', { path: '/settings', resetNamespace: true });
      this.route('search', { path: '/search', resetNamespace: true });

      this.route('projects', { path: '/projects', resetNamespace: true });
    });

    this.route('public', function () {
      this.route('login', { path: '/login', resetNamespace: true });
      this.route('help', { path: '/help', resetNamespace: true });
    });
  });

  exports.default = Router;
});