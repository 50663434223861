define('clacksnack/mirage/factories/asset', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    mediaType: "image",
    poster: function (i) {
      return `https://picsum.photos/600/450?image=${i}`;
    },
    media: function (i) {
      return `https://picsum.photos/800/600?image=${i}`;
    },
    isLink: (0, _emberCliMirage.trait)({
      mediaType: "link",
      url: "https://www.agenturfamilie.de/",
      title: _faker.default.lorem.sentence,
      description: _faker.default.lorem.paragraph
    }),
    isEmbeddedVideo: (0, _emberCliMirage.trait)({
      mediaType: "embedded-video",
      media: "https://www.youtube.com/embed/tqYfC1eV568",
      title: _faker.default.lorem.sentence,
      description: _faker.default.lorem.paragraph
    })
  });
});