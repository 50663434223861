define('clacksnack/components/project-radar/-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //attributes
        isVisible: null,
        buttonModal: null,
        project: null,

        //actions
        actions: {
            closeModal() {
                this.set('isVisible', false);
            },
            closeModalTwo() {
                this.set('buttonModal', false);
            }
        }
    });
});