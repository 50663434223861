define('clacksnack/components/post-panel/-comments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['post__comments'],
    classNameBindings: ['isExpanded'],

    post: null,
    idx: 0,
    amount: 5,

    // States
    isEditingComment: false,
    isExpanded: false,

    scrollHeight: 0,

    // Computed Properties

    hasComments: Ember.computed('idx', function () {
      return this.get('idx') > 0;
    }),

    comments: Ember.computed('post.comments', 'idx', function () {
      const comments = this.get('post.comments').toArray();
      const currentIndex = this.get('idx');
      return comments.filter((item, idx) => {
        return currentIndex <= idx;
      });
    }),

    onToggle: Ember.observer('isExpanded', function () {
      const isExpanded = this.get('isExpanded');

      if (isExpanded) {
        const scrollHeight = this.$().prop('scrollHeight');
        this.$().css({
          "max-height": scrollHeight
        });
        this.$().on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
          this.$().off('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend');
          this.$().css({
            "max-height": ""
          });
        });
      }
    }),

    init: function () {
      this._super(...arguments);
      const comments = this.get('post.comments').toArray();
      const length = comments.length;
      const idx = length - this.get('amount');
      this.set('idx', idx);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    actions: {

      loadMore: function () {
        const idx = this.get('idx');
        const amount = this.get('amount');
        if (idx > 0) {
          this.set('idx', idx - amount);
        }
      },

      toggleEditComment: function () {
        this.set('isEditingComment', !this.get('isEditingComment'));
      }
    }
  });
});