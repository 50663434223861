define('clacksnack/helpers/nl2br', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.nl2br = nl2br;
  function nl2br(params, { htmlSafe }) {
    const text = params[0];

    if (Ember.isEmpty(text)) {
      return '';
    }

    const breakTag = '<br />';
    const regex = /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g;
    let formattedText = text.replace(regex, '$1' + breakTag + '$2');

    if (htmlSafe === true) {
      formattedText = Ember.String.htmlSafe(formattedText);
    }

    return formattedText;
  }

  exports.default = Ember.Helper.helper(nl2br);
});