define('clacksnack/adapters/search-item', ['exports', 'clacksnack/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function () {
            return 'search';
        }
    });
});