define('clacksnack/models/employee', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // Attributes
    prename: _emberData.default.attr('string'),
    surname: _emberData.default.attr('string'),
    abbreviation: _emberData.default.attr('string'),
    avatar: _emberData.default.attr('string'),

    birthday: _emberData.default.attr('date'),
    email: _emberData.default.attr('string'),
    messenger: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    mobile: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    video: _emberData.default.attr('string'),

    isActive: _emberData.default.attr('boolean'),

    // Relationships
    posts: _emberData.default.hasMany('post', { async: true }),
    comments: _emberData.default.hasMany('comment', { async: true }),
    location: _emberData.default.belongsTo('location', { async: true }),
    competences: _emberData.default.hasMany('competence', { async: false }),
    articles: _emberData.default.hasMany('article', { async: false }),
    projects: _emberData.default.hasMany('project', { async: false }),
    organisation: _emberData.default.belongsTo('organisation', { async: true }),
    supervisedBy: _emberData.default.belongsTo('employee', { async: true, inverse: 'supervises' }),
    managedBy: _emberData.default.belongsTo('employee', { async: true, inverse: 'manages' }),
    supervises: _emberData.default.hasMany('employee', { async: true, inverse: 'supervisedBy' }),
    manages: _emberData.default.hasMany('employee', { async: true, inverse: 'managedBy' }),
    roles: _emberData.default.hasMany('role', { async: false }),

    // Ember related attributes or functions
    fullname: Ember.computed('surname', 'prename', function () {
      return this.get('prename') + ' ' + this.get('surname');
    })
  });
});