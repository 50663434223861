define('clacksnack/components/post-stream/-alert', ['exports', 'clacksnack/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        intl: Ember.inject.service(),
        stream: Ember.inject.service('post-stream'),
        settings: Ember.inject.service('app-settings'),

        classNameBindings: ['isVisible'],
        classNames: ['post-stream__alert'],

        isVisible: Ember.computed('stream.hasUpdates', function () {
            return this.get('stream').get('hasUpdates');
        }),

        watch: Ember.observer('stream.hasUpdates', function () {
            if (this.get('stream').get('hasUpdates')) {
                this.notifyUser();
            }
        }),

        notifyUser: function () {

            const settings = this.get('settings');
            const self = this;

            if (settings.config.showNotifications) {
                if (!document.hasFocus()) {
                    Push.create(_environment.default.notification.title, {
                        icon: _environment.default.notification.icon,
                        body: self.intl.t('notification.message'),
                        onClick: function () {
                            window.focus();
                            this.close();
                            self.update();
                        }
                    });
                }
            }
        },

        update: function () {
            const stream = this.get('stream');
            stream.set('hasUpdates', false);
            stream.clear();
            stream.set("isLoading", true);
            stream.load({}, results => {
                stream.set("isLoading", false);
                this.get("stream").get("posts").pushObjects(results.toArray());
            });
        },

        click: function () {
            this.update();
        }
    });
});