define('clacksnack/components/project-radar/-form-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        // classNames: ['form-button'],
        modalIsVisible: false,

        //actions
        actions: {
            showModal() {
                this.set('modalIsVisible', true);
            }
        }
    });
});