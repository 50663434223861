define('clacksnack/mirage/config', ['exports', 'ember-cli-mirage', 'faker', 'moment'], function (exports, _emberCliMirage, _faker, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.logging = true;
    this.urlPrefix = 'http://localhost';
    this.namespace = '/cs/csuk/clacksnack-skeleton/public';

    this.passthrough('http://em.cstorage.de/occurrences');
    this.passthrough('https://clacksnacktest.clacks.de/conterior/api/password/change');
    this.passthrough('http://localhost:4200/assets/**');

    // this.timing = 5000;

    // Posts

    this.get('/posts', (schema, request) => {

      const posts = schema.posts.all();

      //
      //
      //
      if (request.queryParams.before) {
        const beforeDate = (0, _moment.default)(request.queryParams.before);
        return posts.filter(post => {
          const postDate = (0, _moment.default)(post.postedOn);
          return beforeDate.diff(postDate) > 0;
        }).filter((post, idx) => {
          return idx < 5;
        });
      }

      //
      //
      //
      if (request.queryParams.after) {
        const afterDate = (0, _moment.default)(request.queryParams.after);
        return posts.filter(post => {
          const postDate = (0, _moment.default)(post.postedOn);
          return afterDate.diff(postDate) < 0;
        });
      }

      // The first 5 newest items of the stream
      // 
      // 
      const sortedPosts = posts.sort((a, b) => {
        const aDate = (0, _moment.default)(a.postedOn).unix();
        const bDate = (0, _moment.default)(b.postedOn).unix();
        return bDate - aDate;
      });

      return sortedPosts.filter((post, idx) => {
        return idx < 5;
      });
    });

    this.get('/posts/:id');

    this.post('/posts', (schema, request) => {

      //
      // return new Mirage.Response(500, {}, {
      //   responseJSON: {
      //     errors: [
      //       {
      //         code: 500,
      //       }
      //     ]}
      //   }
      // );

      const data = JSON.parse(request.requestBody).data;
      const attributes = data.attributes;
      const relationships = data.relationships;

      const employee = schema.db.employees.find(relationships.employee.data.id);
      const now = new Date();

      const post = schema.posts.create({
        fulltext: attributes.fulltext,
        postedOn: now,
        isPublished: false
      });

      post.employeeId = employee.id;
      return post;
    });
    this.post('/posts/:id/assets', (schema, request) => {

      // return new Mirage.Response(500, {}, {
      //   responseJSON: {
      //     errors: [
      //       {
      //         code: 500,
      //       }
      //     ]}
      //   }
      // );

      var attrs = JSON.parse(request.requestBody).data.attributes;
      attrs.postId = request.params.id;

      return schema.assets.create(attrs);
    });

    this.post('/posts/:id/comments', (schema, request) => {
      var data = JSON.parse(request.requestBody).data;
      var attrs = data.attributes;
      attrs.postId = request.params.id;
      attrs.employeeId = data.relationships.employee.data.id;
      return schema.comments.create(attrs);
    });

    this.patch('/posts/:id');

    // Comments

    this.get('/comments');
    this.get('/comments/:id');
    this.patch('/comments/:id');

    // Employee

    this.get('/employees');
    this.get('/employees/:id');
    this.patch('/employees/:id');

    // OpenGraph

    this.get('/opengraph', (schema, request) => {

      let url = decodeURI(request.queryParams.url);
      let mediaType = 'link';

      if (url.indexOf('youtu.be') > -1 || url.indexOf('youtube.com') > -1) {
        url = 'https://www.youtube.com/embed/tqYfC1eV568';
        mediaType = 'embedded-video';
      }

      if (request.queryParams.url) {
        return schema.opengraph.create({
          poster: _faker.default.image.city(),
          title: _faker.default.lorem.sentence(),
          description: _faker.default.lorem.paragraph(),
          media: url,
          mediaType: mediaType
        });
      }

      return null;
    });

    // Organisation

    this.get('/organisations');
    this.get('/organisations/:id');

    // Location

    this.get('/locations');
    this.get('/locations/:id');

    // Event

    this.post('/events', function (schema, request) {
      const data = JSON.parse(request.requestBody).data;

      const employee = schema.employees.find(data.relationships.employee.data.id);
      const source = schema[data.relationships.source.data.type].find(data.relationships.source.data.id);

      const event = schema.events.create({
        eventType: data.attributes.eventType,
        timestamp: _faker.default.date.past,
        employee,
        source
      });

      return event;
    });
    this.delete('/events/:id');

    // Session
    ////////////

    this.post('/sessions', (schema, request) => {
      var credentials = JSON.parse(request.requestBody);

      if (credentials.password !== 'clack2016') {
        return new _emberCliMirage.default.Response(403, { some: 'header' }, { message: 'forbidden' });
      }

      const loggedInUser = schema.db.employees.find(1);

      const session = schema.sessions.create({
        'token': '676765319965ba4cf49e8e43f8aae187',
        'date-of-expiry': '2017-06-31T13:15:23'
      });

      session.employeeId = loggedInUser.id;

      return session;
    });

    // Widgets

    this.get('/widgets', schema => {
      let widgets = schema.widgets.all();

      widgets.models = widgets.models.map(widget => {
        widget.details = null;
        return widget;
      });

      return widgets;
    });

    this.get('/widgets/:id', (schema, request) => {
      return schema.widgets.find(request.params.id);
    }, { timing: 3000 });

    this.patch('/widgets/:id', (schema, request) => {
      let widget = schema.widgets.find(request.params.id);
      widget.details.isEditable = false;
      return widget;
    });

    // Settings

    this.get('/settings');
    this.post('/settings');
    this.put('/settings');

    // Search
    // Will be refactored when refactoring search in whole
    this.get('/search', function (schema, request) {

      if (request.queryParams.query) {
        return {
          data: [{
            id: 1,
            type: 'search-item',
            attributes: {
              'item-type': 'post'
            },
            relationships: {
              post: {
                id: 1771,
                type: 'post'
              }
            }
          }],
          included: [{ "type": "post", "id": "1771", "attributes": { "fulltext": "test test", "posted-on": "2018-01-02T10:00:15+01:00", "is-published": true }, "relationships": { "employee": { "data": { "type": "employee", "id": "14" } } } }]
        };
      }

      return {
        data: []
      };
    });

    this.get('/competences');
    this.get('/teams');

    // this.get('/projects');
    this.get('/project-categories');
  };
});