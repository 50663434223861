define('clacksnack/components/login-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        notifications: Ember.inject.service('notification-messages'),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service(),

        //

        classNames: ['login-form'],
        classNameBindings: ['isLoading'],

        //

        email: '',
        password: '',

        //

        bgImage: Ember.computed(function () {
            return Math.floor(Math.random() * (7 - 1) + 1);
        }),

        //

        isLoading: false,
        hasError: false,

        //

        // isDisabled: Ember.computed('email', 'password', function() {
        //     const email = this.get('email');
        //     const password = this.get('password');
        //     return Ember.isEmpty(email) || Ember.isEmpty(password);
        // }),

        resetHasError: Ember.observer('hasError', function () {
            if (this.get('hasError')) {
                Ember.run.later(() => {
                    this.set('hasError', false);
                }, 301);
            }
        }),

        onSubmit: function () {

            this.get('notifications').clearAll();
            this.set('hasError', false);
            this.set('isLoading', true);

            const email = this.get('email');
            const password = this.get('password');

            if (Ember.isEmpty(email)) {
                //
            }

            if (Ember.isEmpty(password)) {
                // 
            }

            let session = this.get('store').createRecord('session', {
                email: email,
                password: password
            });

            let request = session.save();
            request.then(session => {
                this.get('session').login(session);
                this.get('router').transitionTo('protected');
            }, () => {
                this.get('notifications').error('Anmeldung fehlgeschlagen: Überprüfen Sie Email und Passwort!', {
                    autoClear: false,
                    clearDuration: 5000,
                    cssClasses: 'notification--error'
                });
                this.set('hasError', true);
                this.set('isLoading', false);
                session.deleteRecord();
            });
        },

        actions: {

            submit() {
                this.onSubmit();
            }
        }
    });
});