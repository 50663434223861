define("clacksnack/components/project-radar/-landscape", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,
    organisationProjects: null,
    veranstaltungenProjects: null,
    toolsProjects: null,
    kommunikationProjects: null,
    wissenProjects: null,
    modalIsVisible: false,
    currentSelectedProject: null,

    setData(data) {
      let orgaProjects = [];
      let veranstProjects = [];
      let toolsProjects = [];
      let kommuProjects = [];
      let wissenProjects = [];

      data.forEach(project => {
        // Organisation
        if (project.color === "#004B87") {
          orgaProjects.push(project);
        }
        // Veranstaltungen
        if (project.color === "#B1C346") {
          veranstProjects.push(project);
        }
        // Tools
        if (project.color === "#C4272E") {
          toolsProjects.push(project);
        }
        // Kommunikation
        if (project.color === "#009AA7") {
          kommuProjects.push(project);
        }
        // Wissen
        if (project.color === "#EEA816") {
          wissenProjects.push(project);
        }
      });

      this.set("wissenProjects", wissenProjects);
      this.set("kommunikationProjects", kommuProjects);
      this.set("toolsProjects", toolsProjects);
      this.set("veranstaltungenProjects", veranstProjects);
      this.set("organisationProjects", orgaProjects);
    },

    changedData: Ember.observer("data", function () {
      const data = this.get("data");
      this.setData(data);
    }),

    // Actions
    actions: {
      showModal(project) {
        this.set("currentSelectedProject", project);
        this.set("modalIsVisible", true);
      }
    }
  });
});