define('clacksnack/components/change-password-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    notifications: Ember.inject.service('notification-messages'),
    intl: Ember.inject.service(),
    user: Ember.inject.service('user'),

    notify: null,

    oldPassword: null,
    newPassword: null,
    repeatPassword: null,

    hasRequiredCharacterLength: Ember.computed('newPassword', function () {
      const input = this.get('newPassword');
      return input && input.length >= 12;
    }),
    containsLowerCharacter: Ember.computed('newPassword', function () {
      const input = this.get('newPassword');
      return input.toUpperCase() !== input;
    }),
    containsUpperCharacter: Ember.computed('newPassword', function () {
      const input = this.get('newPassword');
      return input.toLowerCase() !== input;
    }),
    containsNumber: Ember.computed('newPassword', function () {
      const input = this.get('newPassword');
      const numberRegex = /\d/;
      return numberRegex.test(input);
    }),
    containsSpecialCharacter: Ember.computed('newPassword', function () {
      const input = this.get('newPassword');
      const specialCharacterRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      return specialCharacterRegex.test(input);
    }),

    init() {
      this._super(...arguments);
      this.onReset();
    },

    onReset() {
      this.set('oldPassword', '');
      this.set('newPassword', '');
      this.set('repeatPassword', '');
    },

    onSuccess() {},
    onCancel() {},
    onChange() {

      const oldPassword = this.get('oldPassword');
      const newPassword = this.get('newPassword');
      const repeatPassword = this.get('repeatPassword');

      const payload = {
        oldPassword,
        newPassword,
        repeatPassword
      };

      const task = this.get('user').changePassword(payload);

      task.done(() => {
        const successMessage = this.get('intl').t('appStatusCode.500');
        this.onReset();
        this.onSuccess();
        this.get('notifications').success(successMessage, {
          autoClear: true,
          clearDuration: 5000,
          cssClasses: 'notification--sucess'
        });
      });

      task.fail(response => {
        const errorMessage = `${this.get('intl').t('appStatusCode.' + response.responseJSON.errors[0].code)} (${response.responseJSON.errors[0].code})`;
        this.get('notifications').error(errorMessage, {
          autoClear: true,
          clearDuration: 5000,
          cssClasses: 'notification--error'
        });
      });
    },

    actions: {
      submit: function () {
        this.onChange();
      },
      cancel: function () {
        this.onCancel();
        this.onReset();
      }
    }
  });
});