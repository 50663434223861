define('clacksnack/routes/post', ['exports', 'clacksnack/mixins/tracking'], function (exports, _tracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tracking.default, {

    model: function (params) {
      return this.store.find('post', params.id);
    }
  });
});