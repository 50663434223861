define('clacksnack/routes/protected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel() {
      const session = this.get('session');
      if (!session.isLoggedIn()) {
        session.logout();
      }
    },

    model() {
      const session = this.get('session').get('session');
      return this.store.findRecord('employee', session.employeeId);
    },

    actions: {
      willTransition() {
        const session = this.get('session');
        if (!session.isLoggedIn()) {
          session.logout();
        }
      }
    }
  });
});