define('clacksnack/models/comment', ['exports', 'ember-data', 'clacksnack/models/event-source'], function (exports, _emberData, _eventSource) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _eventSource.default.extend({

		// Attributes

		fulltext: _emberData.default.attr('string'),
		postedOn: _emberData.default.attr('date'),

		// Relationships

		employee: _emberData.default.belongsTo('employee', { async: true }),
		post: _emberData.default.belongsTo('post', { async: true }),

		likes: Ember.computed('events.[]', function () {
			return this.get('events').filterBy('eventType', 'like');
		})
	});
});