define('clacksnack/transforms/date', ['exports', '@ember-data/serializer/-private'], function (exports, _private) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _private.DateTransform;
    }
  });
});