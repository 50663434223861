define("clacksnack/services/environment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const platforms = [{
    label: "ios",
    regex: /(iPhone|iPad|iOS)/
  }, {
    label: "android",
    regex: /(Android)/
  }];

  exports.default = Ember.Service.extend({

    features: null,
    platform: null,

    init() {
      this._super(arguments);
      this.set("platform", this.detectPlatform());
      const features = {
        touch: this.detectTouch()
      };
      this.set("features", features);
    },

    has(feature) {
      return this.get("features")[feature] || false;
    },
    is(platform) {
      return this.get("platform") === platform;
    },

    detectTouch() {
      // eslint-disable-next-line no-undef
      const hasTouch = 'ontouchstart' in window || window.TouchEvent || window.DocumentTouch && document instanceof DocumentTouch;
      const isMobile = this.get("platform") === "ios" || this.get("platform") === "android";
      return hasTouch && isMobile;
    },
    detectPlatform() {
      for (let i = 0; i < platforms.length; i++) {
        const platform = platforms[i];
        if (platform.regex.test(navigator.userAgent)) {
          return platform.label;
        }
      }
      return "pc";
    }
  });
});