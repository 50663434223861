define("clacksnack/models/project", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    idea: _emberData.default.attr("string"),
    timeplan: _emberData.default.attr("string"),
    budget: _emberData.default.attr("string"),
    teamworkLink: _emberData.default.attr("string"),

    //Relationships
    projectOwner: _emberData.default.belongsTo("employee", { async: true, inverse: "projects" }),
    chef: _emberData.default.belongsTo("employee", { async: true, inverse: null }),
    members: _emberData.default.hasMany("employee", { async: true, inverse: null }),
    organisations: _emberData.default.hasMany("organisation", { async: true }),
    projectStates: _emberData.default.hasMany("project-state"),
    category: _emberData.default.belongsTo("project-category")
  });
});