define('clacksnack/services/post-stream', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        store: Ember.inject.service(),

        posts: null,
        hasUpdates: false,
        isLoading: false,

        firstItem: Ember.computed('posts.[]', function () {
            return this.get('posts').get('firstObject');
        }),

        lastItem: Ember.computed('posts.[]', function () {
            return this.get('posts').get('lastObject');
        }),

        prepend: function (posts) {
            this.get('posts').unshiftObjects(posts.toArray());
        },

        append: function (posts) {
            this.get('posts').pushObjects(posts.toArray());
        },

        clear: function () {
            this.set('posts', []);
        },

        load: function (params, callback) {
            this.get("store").query("post", params).then(callback);
        }
    });
});