define('clacksnack/components/widget/employee-presence/-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['widget-employee-presence-details'],
    presence: null
  });
});