define('clacksnack/controllers/employees', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    globalConfig: Ember.inject.service(),

    searchInput: null,
    locations: null,
    organisations: null,

    isSidebarOpen: false,
    showFormerEmployees: false,

    init() {
      this._super(...arguments);
      const config = this.get('globalConfig').get('config');
      const organisations = config.organisations.map(item => {
        return Ember.Object.create({
          name: item,
          isActive: false
        });
      });
      this.set('organisations', Ember.A(organisations));
      const locations = config.locations.map(item => {
        return Ember.Object.create({
          name: item,
          isActive: false
        });
      });
      this.set('locations', Ember.A(locations));
    },

    filterByLocation(employeeList) {
      const locations = this.get('locations').filterBy('isActive', true);
      if (locations.length > 0) {
        const employees = Ember.A([]);
        locations.forEach(location => {
          const temp = employeeList.filterBy('location.name', location.name);
          employees.pushObjects(temp);
        });
        return employees;
      }
      return employeeList;
    },

    filterByOrganisation(employeeList) {
      const organisations = this.get('organisations').filterBy('isActive', true);
      if (organisations.length > 0) {
        const employees = Ember.A([]);
        organisations.forEach(organisation => {
          const temp = employeeList.filterBy('organisation.name', organisation.name);
          employees.pushObjects(temp);
        });
        return employees;
      }
      return employeeList;
    },

    filterBySearch(employeeList) {
      const searchInput = this.get('searchInput');
      if (searchInput && searchInput.length > 0) {
        const query = searchInput.toLowerCase();
        return employeeList.filter(item => {
          const name = item.get('fullname').toLowerCase();
          return name.indexOf(query) >= 0;
        });
      }
      return employeeList;
    },

    filterByFormerState(employeeList) {
      const showFormerEmployees = this.get('showFormerEmployees');
      if (!showFormerEmployees) {
        return employeeList.filterBy('isActive', true);
      }
      return employeeList;
    },

    filteredEmployees: Ember.computed('locations.@each.isActive', 'organisations.@each.isActive', 'searchInput', 'showFormerEmployees', 'model', function () {

      const employeeList = this.get('model');
      let filteredEmployees = this.filterByFormerState(employeeList);
      filteredEmployees = this.filterByLocation(filteredEmployees);
      filteredEmployees = this.filterByOrganisation(filteredEmployees);
      filteredEmployees = this.filterBySearch(filteredEmployees);

      return filteredEmployees;
    }),

    actions: {
      openSidebar: function () {
        this.set('isSidebarOpen', true);
      },
      closeSidebar: function () {
        this.set('isSidebarOpen', false);
      }
    }
  });
});