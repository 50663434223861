define('clacksnack/components/user-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'user-area',
    classNameBindings: 'isExpanded',

    store: Ember.inject.service(),
    session: Ember.inject.service(),

    isExpanded: false,

    loggedInUser: Ember.computed('session', function () {
      const session = this.get('session').get('session');
      return this.get('store').peekRecord('employee', session.employeeId);
    }),

    didInsertElement: function () {
      this._super(...arguments);
      const container = this.$();

      this.$(window).click(e => {
        if (container.has(e.target).length == 0 && !container.is(e.target)) {
          this.set('isExpanded', false);
        }
      });
    },

    willDestroyElement: function () {
      this._super(...arguments);
      this.$(window).off('click');
    },

    actions: {
      toggle: function () {
        const isExpanded = this.get('isExpanded');
        this.set('isExpanded', !isExpanded);
      },
      close: function () {
        this.set('isExpanded', false);
      },
      logout: function () {
        this.get('session').logout();
      }
    }
  });
});