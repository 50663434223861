define('clacksnack/initializers/tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line no-undef
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    window.gtag = gtag;
  }

  exports.default = {
    initialize
  };
});