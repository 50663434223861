define('clacksnack/controllers/employees/list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    employees: Ember.inject.controller(),
    session: Ember.inject.service(),

    filteredEmployees: Ember.computed('employees.filteredEmployees', function () {
      return this.removeLoggedInUserFromList(this.get("employees.filteredEmployees"));
    }),

    removeLoggedInUserFromList(employeeList) {
      const session = this.get('session').get('session');
      return employeeList.filter(employee => {
        return employee.id !== session.employeeId;
      });
    }
  });
});